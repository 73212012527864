import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
  Tooltip,
} from "antd";
import styled from "styled-components";
import colorPanelService from "../../../services/color-panel.service";
import roundDiamond from "../../../assets/images/daimond-link.jpg";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import NoPreview from "../../../assets/images/no_pre.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import {
  handleCompleteEarningTwoDiamond,
  handleEditViewShow,
  handleEditViewHide,
} from "../../../actions/chooseYourSetting/chooseYourSettingActions";
import {
  handleViewCompletethisMounting,
  handleHideCompletethisMounting,
  handleHideEditChooseThisMounting,
} from "../../../actions/chooseYourEarning/chooseYourEarningActions";
import completeYourRing from "../../../services/complete-your-ring.service";
import {
  handleComaparePageIds,
  handleSetSaveEveryTimeMinedTab,
  handleSetSaveFilterMinedTab,
  handleViewAddToStudPageDetails,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { handleActiveTab } from "../../../actions/header/headerActions";

const initialCertInfoData = {
  name: "",
  email: "",
  phoneNumber: "",
  comments: "",
};

const initialCertInfoDataVal = {
  nameVal: "",
  emailVal: "",
  phoneNumberVal: "",
  commentsVal: "",
};

const initialOptionsData = {
  studbuilderLinkURL: "",
  internalUseLink: true,
  showOnlyDiamondSearch: false,
  showDealerDiamondsSKU: true,
  showScheduleViewing: true,
  showAddress: true,
  showCallForPrice: false,
  applyGstTax: true,
  gstTaxValue: "",
  showPinterestShare: true,
  showTwitterShare: true,
  showFacebookShare: true,
  showFacebookLike: true,
  showAddToCartButton: true,
  ShoppingCartURL: "",
  showViewCartButton: true,
  ShowInstagramPlus: true,
};

const MinedDiamond = ({}) => {
  const { Search } = Input;
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});
  const [isAdvance, setIsAdvance] = useState(false);
  const [viewItem, setViewItem] = useState("list");
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [minedList, setMinedList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [certInfoData, setcertInfoData] = useState(initialCertInfoData);
  const [certInfoValidation, setcertInfoDataValidation] = useState(
    initialCertInfoDataVal
  );
  const [certificateDetails, setCertificateDetails] = useState({});
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
  const [showAllContent, setShowAllContent] = useState(true);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });

  const handleGetstudBuilderOptiondetails = (id) => {
    let inputData = {
      dealerID: id,
    };

    try {
      SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setMyOptionsData((prevState) => ({
                  ...prevState,
                  studbuilderLinkURL: responseData.studBuilderURL,
                  internalUseLink: responseData.internalLinkSB,
                  showOnlyDiamondSearch: responseData.onlyDiamondSearch,
                  showDealerDiamondsSKU: responseData.stud_ShowSKU,
                  showScheduleViewing: responseData.showScheduleView,
                  showAddress: responseData.showAddresses,
                  showCallForPrice: responseData.callForPricestud,
                  applyGstTax: responseData.showGstPrice,
                  gstTaxValue: responseData.taxRate,
                  showPinterestShare: responseData.showPinterestShare,
                  showTwitterShare: responseData.showTwitterShare,
                  showFacebookShare: responseData.showFacebookShare,
                  showFacebookLike: responseData.showFacebookLike,
                  showAddToCartButton: responseData.showAddToCartButtonStud,
                  ShoppingCartURL: responseData.diamondstudshoppingurl,
                  showViewCartButton: responseData.showViewCartButtonStud,
                  ShowInstagramPlus: responseData.showInstagramShare,
                }));
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setMyOptionsData((prevState) => ({
            showOnlyDiamondSearch: false,
          }));
        });
    } catch (error) {
      console.log(error);
      setMyOptionsData((prevState) => ({
        showOnlyDiamondSearch: false,
      }));
    }
  };

  useLayoutEffect(() => {
    if (paramdealerId) {
      if (paramdealerId) {
        handleGetColorsData(paramdealerId);
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "StudBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
                setStyles(tempobj);
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
                setStyles(tempobj);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
          setColorStyles(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
          setColorStyles(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  function setStyles(obj) {
    document.documentElement.style.setProperty(
      "--background--table--color",
      obj.columnHeaderAccent
    );
  }

  function setColorStyles(val) {
    document.documentElement.style.setProperty(
      "--link--table--color",
      val ? "#3b4452" : "#ffffff"
    );
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  useEffect(() => {
    if (paramdealerId) {
      if (paramdealerId) {
        if (resetVal) {
          setTimeout(() => {
            handleGetMinedDiamondFilters(
              paramdealerId,
              pageNo,
              pageSize,
              resetVal.saveSearchMined
            );
          }, 100);

          setDealerId(paramdealerId);
          handleGetstudBuilderOptiondetails(paramdealerId);
        }
        dispatch(handleHideCompletethisMounting());
        dispatch(handleEditViewHide());
      }
    }
  }, [resetVal.resetMined, resetVal.saveSearchMined]);

  const handleOnChange = (row, e) => {
    const temp = minedList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setMinedList(temp);
    let CompareIds = saveRow.map((x) => x.intDiamondId);
    let compareData = {
      ids: CompareIds,
      pageName: "Mined",
    };
    dispatch(handleComaparePageIds(compareData));
  };

  const handleChooseFirstSetting = (details) => {
    let obj = details;

    dispatch(handleViewAddToStudPageDetails(obj));
  };

  const handleChooseSetting = (details) => {
    let obj = details;
    navigate(
      "/chooseYourSetting" +
        `?isSocialPage=True&DealerID=${paramdealerId}&dealerID=${obj.dealerID1}&paramDetailLinkURL=${obj.detailLinkURL}&diamondId=${obj.diamondID1}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&size=${obj.size1}&pairId=${obj.pairID}&dealerID1=${obj.dealerID1}&totalPrice=${obj.totalPrice}&isTab=false&tabName=chooseyourmounting&diamondViewEdit=True`
    );

    localStorage.setItem("selectedDimaondDetails", JSON.stringify(obj));

    dispatch(handleViewAddToStudPageDetails(obj));
    dispatch(handleViewCompletethisMounting());
    dispatch(handleHideEditChooseThisMounting());
    dispatch(handleEditViewShow());
    dispatch(handleCompleteEarningTwoDiamond(obj));

    dispatch(handleActiveTab("chooseYourSetting"));
  };

  const navigate = useNavigate();
  const handleCompleteEarningDiamond = (details) => {
    let obj = details;
    dispatch(handleCompleteEarningTwoDiamond(obj));

    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${paramdealerId}&dealerID1=${obj.dealerID1}&paramDetailLinkURL=${obj.detailLinkURL}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&diamondId=${obj.diamondID1}&isTab=false&tabName=selectYourDiamond&shapeFilters=${obj.cut1}&txtCutlet=${obj.cutGrade1}&txtClarity=${obj.clarity1}&intColorPriority=${obj.color1}`
    );
    localStorage.setItem("selectedDimaondDetails", JSON.stringify(obj));
  };

  const columns = [
    {
      title: "Shape",
      dataIndex: "cut1",
      width: 120,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <div className="stud__img">
              <img
                style={{ width: "20px", height: "20px" }}
                src={row.imageFileName}
              />
              <span>{" " + row.cut1}</span>
            </div>

            <div className="stud__img">
              <img
                style={{ width: "20px", height: "20px" }}
                src={row.imageFileName}
              />
              <span>{" " + row.cut2}</span>
            </div>
          </div>
        );
      },
    },
    ...(myOptionsData.showDealerDiamondsSKU == true
      ? [
          {
            title: "SKU",
            dataIndex: "intDiamondId",
            width: 100,
            render: (item, row) => {
              const style = {
                display: "inline-block",
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              };
              return (
                <div>
                  <Tooltip title={row.dealerInventoryNo1}>
                    <div style={style}>{row.dealerInventoryNo1}</div>
                  </Tooltip>
                  <br />
                  <Tooltip title={row.dealerInventoryNo2}>
                    <div style={style}>{row.dealerInventoryNo2}</div>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: "Carat",
      dataIndex: "size1",
      width: 80,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.size1}</span>
            <br></br>
            <span>{row.size2}</span>
          </div>
        );
      },
    },
    {
      title: "Color",
      dataIndex: "color1",
      width: 80,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.color1}</span>
            <br></br>
            <span>{row.color2}</span>
          </div>
        );
      },
    },
    {
      title: "Clarity",
      dataIndex: "clarity1",
      responsive: ["lg"],
      width: 80,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.clarity1}</span>
            <br></br>
            <span>{row.clarity2}</span>
          </div>
        );
      },
    },
    {
      title: "Cut",
      dataIndex: "cutGrade1",
      responsive: ["lg"],
      width: 100,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.cutGrade1}</span>
            <br></br>
            <span>{row.cutGrade2}</span>
          </div>
        );
      },
    },
    {
      title: "Depth",
      dataIndex: "depth1",
      width: 80,
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.depth1}</span>
            <br></br>
            <span>{row.depth2}</span>
          </div>
        );
      },
    },
    {
      title: "Table",
      dataIndex: "tableMeasure1",
      width: 80,
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.tableMeasure1}</span>
            <br></br>
            <span>{row.tableMeasure2}</span>
          </div>
        );
      },
    },
    {
      title: "Polish",
      dataIndex: "polish1",
      width: 100,
      //className: "hideInMobile",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.polish1}</span>
            <br></br>
            <span>{row.polish2}</span>
          </div>
        );
      },
    },
    {
      title: "Sym.",
      dataIndex: "symmetry1",
      width: 120,
      //className: "hideInMobile",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.symmetry1}</span>
            <br></br>
            <span>{row.symmetry2}</span>
          </div>
        );
      },
    },
    {
      title: "Measure.",
      //className: "hideInMobile",
      width: 150,
      responsive: ["lg"],
      dataIndex: "measurements1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.measurements1}</span>
            <br></br>
            <span>{row.measurements2}</span>
          </div>
        );
      },
    },
    {
      title: "Cert.",
      dataIndex: "certificate1",
      responsive: ["lg"],
      width: 80,
      className: "my-hover-pointer",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.certificateLink !== "" && (
              <span
                className="my-hover-pointer linkText"
                onClick={() => handleOpenCertificate(row.certificateLink)}
              >
                {row.certificate1}
              </span>
            )}

            <br></br>
            {row.certificateLink2 !== "" && (
              <span
                className="my-hover-pointer linkText"
                onClick={() => handleOpenCertificate(row.certificateLink2)}
              >
                {row.certificate2}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: `Price (${rowDetails?.currencycode ?? ""})`,
      dataIndex: "totalPrice",
      width: 100,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return myOptionsData.showCallForPrice == true ? (
          <span>Call For Price</span>
        ) : (
          <>
            {row.totalPrice == "0" ? (
              <>
                Call <span className="hideInMobile">For Price</span>
              </>
            ) : (
              <span>
                {/* {row?.currencycode ?? ""} */}
                {row?.currencySign}
                {row.currency}
                {parseInt(row.totalPrice)
                  .toFixed()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            )}
          </>
          // <span>{"$" + row.totalPrice}</span>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleCompleteEarningDiamond(row)}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const { Option } = Select;

  const handleProductChangeOpen = (intDiamondId) => {
    let newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    let newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    let StrValue = e.target.value && e.target.value.replace(currencySymbol, "");
    let Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        let tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[0] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["PriceMin"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      // handleGetMinedDiamondList(
      //   dealerId,
      //   1,
      //   pageSize,
      //   filterVals.Shape,
      //   newArr[0] == "" ? 0 : newArr[0],
      //   priceValue[1],
      //   caratSlideValue[0],
      //   caratSlideValue[1],
      //   state.Color,
      //   state.Clarity,
      //   state.Cut,
      //   state.Polish,
      //   state.Symmetry,
      //   state.Fluorescence,
      //   filterVals.DepthMin,
      //   filterVals.DepthMax,
      //   filterVals.TableMin,
      //   filterVals.TableMax,
      //   filterVals.Certificates, // Certificate
      //   searchSku, //Sku
      //   filterVals.Color, // color
      //   filterVals.Clarity, // clarity
      //   filterVals.Cut, // cut
      //   filterVals.Polish, // polish
      //   filterVals.Symmetry, //symmetry
      //   filterVals.Fluorescence, //fluorescence
      //   true, //isFilter
      //   sortColumnName,
      //   sortType
      // );
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        let tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // handleGetMinedDiamondList(
      //   dealerId,
      //   1,
      //   pageSize,
      //   filterVals.Shape,
      //   priceValue[0],
      //   newArr[1] == "" ? 0 : newArr[1],
      //   caratSlideValue[0],
      //   caratSlideValue[1],
      //   state.Color,
      //   state.Clarity,
      //   state.Cut,
      //   state.Polish,
      //   state.Symmetry,
      //   state.Fluorescence,
      //   filterVals.DepthMin,
      //   filterVals.DepthMax,
      //   filterVals.TableMin,
      //   filterVals.TableMax,
      //   filterVals.Certificates, // Certificate
      //   searchSku, //Sku
      //   filterVals.Color, // color
      //   filterVals.Clarity, // clarity
      //   filterVals.Cut, // cut
      //   filterVals.Polish, // polish
      //   filterVals.Symmetry, //symmetry
      //   filterVals.Fluorescence, //fluorescence
      //   true, //isFilter
      //   sortColumnName,
      //   sortType
      // );
    }
  };

  const handleBlur = () => {
    handleGetMinedDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType
    );
  };
  const firstsetCaratSlideValue = (e, num) => {
    let newArr = [...caratSlideValue];
    let StrValue = e.target.value && e.target.value.replace("$", "");
    let Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        let tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      // handleGetMinedDiamondList(
      //   dealerId,
      //   1,
      //   pageSize,
      //   filterVals.Shape,
      //   priceValue[0],
      //   priceValue[1],
      //   newArr[0] == "" ? 0 : newArr[0],
      //   caratSlideValue[1],
      //   state.Color,
      //   state.Clarity,
      //   state.Cut,
      //   state.Polish,
      //   state.Symmetry,
      //   state.Fluorescence,
      //   filterVals.DepthMin,
      //   filterVals.DepthMax,
      //   filterVals.TableMin,
      //   filterVals.TableMax,
      //   filterVals.Certificates, // Certificate
      //   searchSku, //Sku
      //   filterVals.Color, // color
      //   filterVals.Clarity, // clarity
      //   filterVals.Cut, // cut
      //   filterVals.Polish, // polish
      //   filterVals.Symmetry, //symmetry
      //   filterVals.Fluorescence, //fluorescence
      //   true, //isFilter
      //   sortColumnName,
      //   sortType
      // );
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        let tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // handleGetMinedDiamondList(
      //   dealerId,
      //   1,
      //   pageSize,
      //   filterVals.Shape,
      //   priceValue[0],
      //   priceValue[1],
      //   caratSlideValue[0],
      //   newArr[1] == "" ? 0 : newArr[1],
      //   state.Color,
      //   state.Clarity,
      //   state.Cut,
      //   state.Polish,
      //   state.Symmetry,
      //   state.Fluorescence,
      //   filterVals.DepthMin,
      //   filterVals.DepthMax,
      //   filterVals.TableMin,
      //   filterVals.TableMax,
      //   filterVals.Certificates, // Certificate
      //   searchSku, //Sku
      //   filterVals.Color, // color
      //   filterVals.Clarity, // clarity
      //   filterVals.Cut, // cut
      //   filterVals.Polish, // polish
      //   filterVals.Symmetry, //symmetry
      //   filterVals.Fluorescence, //fluorescence
      //   true, //isFilter
      //   sortColumnName,
      //   sortType
      // );
    }
  };

  const onChangeMultiDrop = (e, name) => {
    let filter = [];
    let tempState = filterVals;
    if (e.includes("%")) {
      tempState.disabled[name] = true;
      tempState[name] = [];
      tempState[name].push("%");
    } else {
      tempState.disabled[name] = false;
      tempState[name] = e;
    }
    setFilterVals((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleGetMinedDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Intensity,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      tempState.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Intensity, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter, // isFilter
      sortColumnName,
      sortType
    );
    dispatch(handleSetSaveEveryTimeMinedTab(filterVals));
  };

  function removeSpecialCharactersAndConvertToNumber(inputString) {
    const cleanedString = inputString.replace(/[^0-9.]/g, "");
    // Remove a trailing hyphen, if present
    const cleanedStringWithoutHyphen = cleanedString.replace(/-$/, "");
    // Convert the cleaned string to a number
    return parseFloat(cleanedStringWithoutHyphen, 10);
  }

  const handleLabGrownFilterChange = (val, name, type) => {
    if (type == "filter") {
      let tempArray = filterVals[name];
      if (tempArray.includes(val)) {
        const index = tempArray.indexOf(val);
        tempArray.splice(index, 1);
      } else {
        tempArray.push(val);
      }
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
      if (name == "Shape") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          tempArray,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }

      if (name == "Cut") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
      if (name == "Color") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          tempArray, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
      if (name == "Clarity") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          tempArray, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
      if (name == "Polish") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          tempArray, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
      if (name == "Fluorescence") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          tempArray, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
      if (name == "Symmetry") {
        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          tempArray, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }
    } else {
      // setFilterVals({
      //   ...filterVals,
      //   [name]: val
      // });
      if (
        name == "DepthMin" ||
        name == "DepthMax" ||
        name == "TableMin" ||
        name == "TableMax"
      ) {
        if (val > 100 || val < 0) {
          return false;
        }
        setFilterVals({
          ...filterVals,
          [name]: removeSpecialCharactersAndConvertToNumber(val),
        });
      } else {
        setFilterVals({
          ...filterVals,
          [name]: val,
        });
      }
      if (name == "DepthMin") {
        // handleGetMinedDiamondList(
        //   dealerId,
        //   1,
        //   pageSize,
        //   filterVals.Shape,
        //   priceValue[0],
        //   priceValue[1],
        //   caratSlideValue[0],
        //   caratSlideValue[1],
        //   state.Color,
        //   state.Clarity,
        //   state.Cut,
        //   state.Polish,
        //   state.Symmetry,
        //   state.Fluorescence,
        //   val,
        //   filterVals.DepthMax,
        //   filterVals.TableMin,
        //   filterVals.TableMax,
        //   filterVals.Certificates, // Certificate
        //   searchSku, //Sku
        //   filterVals.Color, // color
        //   filterVals.Clarity, // clarity
        //   filterVals.Cut, // cut
        //   filterVals.Polish, // polish
        //   filterVals.Symmetry, //symmetry
        //   filterVals.Fluorescence, //fluorescence
        //   true, // isFilter
        //   sortColumnName,
        //   sortType
        // );
      }
      if (name == "Certificates") {
        let allFilters = [];

        if (val.includes("all")) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
        } else if (val.length == state.Certificates.length) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
        }

        handleGetMinedDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          allFilters.length > 0 ? allFilters : val, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, // isFilter
          sortColumnName,
          sortType
        );
      }

      if (name == "DepthMax") {
        // handleGetMinedDiamondList(
        //   dealerId,
        //   1,
        //   pageSize,
        //   filterVals.Shape,
        //   priceValue[0],
        //   priceValue[1],
        //   caratSlideValue[0],
        //   caratSlideValue[1],
        //   state.Color,
        //   state.Clarity,
        //   state.Cut,
        //   state.Polish,
        //   state.Symmetry,
        //   state.Fluorescence,
        //   filterVals.DepthMin,
        //   val,
        //   filterVals.TableMin,
        //   filterVals.TableMax,
        //   filterVals.Certificates, // Certificate
        //   searchSku, //Sku
        //   filterVals.Color, // color
        //   filterVals.Clarity, // clarity
        //   filterVals.Cut, // cut
        //   filterVals.Polish, // polish
        //   filterVals.Symmetry, //symmetry
        //   filterVals.Fluorescence, //fluorescence
        //   true, // isFilter
        //   sortColumnName,
        //   sortType
        // );
      }
      if (name == "TableMin") {
        // handleGetMinedDiamondList(
        //   dealerId,
        //   1,
        //   pageSize,
        //   filterVals.Shape,
        //   priceValue[0],
        //   priceValue[1],
        //   caratSlideValue[0],
        //   caratSlideValue[1],
        //   state.Color,
        //   state.Clarity,
        //   state.Cut,
        //   state.Polish,
        //   state.Symmetry,
        //   state.Fluorescence,
        //   filterVals.DepthMin,
        //   filterVals.DepthMax,
        //   val,
        //   filterVals.TableMax,
        //   filterVals.Certificates, // Certificate
        //   searchSku, //Sku
        //   filterVals.Color, // color
        //   filterVals.Clarity, // clarity
        //   filterVals.Cut, // cut
        //   filterVals.Polish, // polish
        //   filterVals.Symmetry, //symmetry
        //   filterVals.Fluorescence, //fluorescence
        //   true, // isFilter
        //   sortColumnName,
        //   sortType
        // );
      }
      if (name == "TableMax") {
        // handleGetMinedDiamondList(
        //   dealerId,
        //   1,
        //   pageSize,
        //   filterVals.Shape,
        //   priceValue[0],
        //   priceValue[1],
        //   caratSlideValue[0],
        //   caratSlideValue[1],
        //   state.Color,
        //   state.Clarity,
        //   state.Cut,
        //   state.Polish,
        //   state.Symmetry,
        //   state.Fluorescence,
        //   filterVals.DepthMin,
        //   filterVals.DepthMax,
        //   filterVals.TableMin,
        //   val,
        //   filterVals.Certificates, // Certificate
        //   searchSku, //Sku
        //   filterVals.Color, // color
        //   filterVals.Clarity, // clarity
        //   filterVals.Cut, // cut
        //   filterVals.Polish, // polish
        //   filterVals.Symmetry, //symmetry
        //   filterVals.Fluorescence, //fluorescence
        //   true, // isFilter
        //   sortColumnName,
        //   sortType
        // );
      }
    }
    dispatch(handleSetSaveEveryTimeMinedTab(filterVals));
    setPageNo(1);
    setPageSize(10);
  };

  const handleGetMinedDiamondFilters = (id, pageNo, pageSize, val) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      setFilterLoading(true);
      setLoading(true);
      SelectYourDiamondService.GetAvailableStudBuilderFilters(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData =
            response.data.responseData.availableFilters_Response;
          let tempAvailableFilterData = state;
          let tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              // tempAvailableFilterData.PriceMin = Number(responseData.priceMin);
              // tempAvailableFilterData.PriceMax = Number(responseData.priceMax);
              // tempAvailableFilterData.CaratMin = Number(responseData.caratMin);
              // tempAvailableFilterData.CaratMax = Number(responseData.caratMax);
              setIsAdvance(responseData.advanced);
              setCurrencySymbol(responseData.currencySign);
              let shapData = [];

              shapData = responseData.studBuilderSettingsShape.filter(
                (x) => x.id !== 0
              );
              tempAvailableFilterData.Shape = shapData;

              // tempAvailableFilterData.Shape =
              //   responseData.studBuilderSettingsShape;

              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              // tempAvailableFilterData.TableMin = responseData.tableMin;
              // tempAvailableFilterData.TableMax = responseData.tableMax;
              // tempAvailableFilterData.DepthMin = responseData.depthMin;
              // tempAvailableFilterData.DepthMax = responseData.depthMax;

              // tempAvailableFilterValData.PriceMin = Number(
              //   responseData.priceMin
              // );
              // tempAvailableFilterValData.PriceMax = Number(
              //   responseData.priceMax
              // );
              // tempAvailableFilterValData.CaratMin = Number(
              //   responseData.caratMin
              // );
              // tempAvailableFilterValData.CaratMax = Number(
              //   responseData.caratMax
              // );
              // tempAvailableFilterValData.TableMin = responseData.tableMin;
              // tempAvailableFilterValData.TableMax = responseData.tableMax;
              // tempAvailableFilterValData.DepthMin = responseData.depthMin;
              // tempAvailableFilterValData.DepthMax = responseData.depthMax;

              setCaratSlideValue([0, 22]);
              setPriceValue([0, 1000000]);
              setState({
                ...state,
                ...tempAvailableFilterData,
              });

              setFilterVals({
                ...filterVals,
                ...tempAvailableFilterValData,
              });
              // setPriceValue([responseData.priceMin, responseData.priceMax]);
              // setCaratSlideValue([
              //   responseData.caratMin,
              //   responseData.caratMax,
              // ]);
              let saveSearchObj = resetVal.minedDiamondFilter;

              if (val == true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                ]);
              } else {
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                let tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 1000000;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;
                // setPriceValue([filterPricemin, filterPricemax]);
                // setCaratSlideValue([filterCaratmin, filterCaratmax]);
                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj,
                });
                dispatch(handleSetSaveFilterMinedTab(tempObj));
              }
              setFilterLoading(false);
              if (val == true) {
                if (val == true) {
                  handleGetMinedDiamondList(
                    id,
                    pageNo,
                    pageSize,
                    saveSearchObj.Shape, //shapes
                    saveSearchObj.PriceMin,
                    saveSearchObj.PriceMax,
                    saveSearchObj.CaratMin,
                    saveSearchObj.CaratMax,
                    responseData.sldColorMax,
                    responseData.sldClarityMax,
                    responseData.sldCutGradeMax,
                    responseData.sldPolishMax,
                    responseData.sldSymmetryMax,
                    responseData.sldFluorescenceMax,
                    saveSearchObj.DepthMin,
                    saveSearchObj.DepthMax,
                    saveSearchObj.TableMin,
                    saveSearchObj.TableMax,
                    saveSearchObj.Certificate, // Certificate
                    searchSku, //Sku
                    saveSearchObj.Color, // color
                    saveSearchObj.Clarity, // clarity
                    saveSearchObj.Cut, // cut
                    saveSearchObj.Polish, // polish
                    saveSearchObj.Symmetry, //symmetry
                    saveSearchObj.Fluorescence, //fluorescence
                    true, // isFilter
                    sortColumnName,
                    sortType
                  );
                }
              } else {
                handleGetMinedDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  [], //shapes
                  responseData.priceMin,
                  responseData.priceMax,
                  responseData.caratMin,
                  responseData.caratMax,
                  responseData.colorMax,
                  responseData.colorMin,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  responseData.sldDepthMin,
                  responseData.sldDepthMax,
                  responseData.sldTableMin,
                  responseData.sldTableMax,
                  "", // Certificate
                  searchSku, //Sku
                  [], // color
                  [], // clarity
                  [], // cut
                  [], // polish
                  [], //symmetry
                  [], //fluorescence
                  false, // isFilter
                  sortColumnName,
                  sortType
                );
              }
            } else {
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const handleGetMinedDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    searchSku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    filter, // isFilter
    column,
    type
  ) => {
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut && allCut.length > 0 ? allCut.map((x) => Number(x.value)) : null;
    let arrColors =
      allColor && allColor.length > 0
        ? allColor.map((x) => Number(x.value))
        : null;
    let arrPolishs =
      allPolish && allPolish.length > 0
        ? allPolish.map((x) => Number(x.value))
        : null;
    let arrClaritys =
      allClarity && allClarity.length > 0
        ? allClarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      allSymmetry && allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      allFluorescence && allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.value))
        : null;

    try {
      // let inputData = {
      //   dealerID: "3075",
      //   shapeList: "Round,Princess",
      //   priceMin: "0",
      //   priceMax: "129000",
      //   caratMin: "0.2",
      //   caratMax: "20",
      //   colorMin: "65",
      //   colorMax: "75",
      //   clarityMin: "1",
      //   clarityMax: "10",
      //   cutgradeMin: "1",
      //   cutgradeMax: "4",
      //   symmetryMin: "1",
      //   symmetryMax: "4",
      //   polishMin: "1",
      //   polishMax: "4",
      //   fluorescenceMin: "1",
      //   fluorescenceMax: "5",
      //   certificate: "%",
      //   skuNo: "",
      //   pairCode: "1",
      //   pageSize: "7",
      //   currentPage: "1",
      //   orderBy: "TotalPrice",
      //   orderType: "Asc",
      //   detailsLink: "StudDiamond_Detail.aspx",
      //   did: "",
      //   depthMin: "0",
      //   depthMax: "6390",
      //   tableMin: "0",
      //   tableMax: "98",
      //   caratPriceMin: "0",
      //   caratPriceMax: "0",
      //   cutGradeList: "2,3",
      //   colorList: "0",
      //   clarityList: "",
      //   polishList: "",
      //   symmetryList: "",
      //   fluorescenceList: "",
      //   extraQStr: "&SID=&Shape=&CTW=&Size=",
      //   themeName: "WhiteTheme",
      //   countryTaxRate: "77",
      //   countryCode: ""
      // };

      let inputData = {
        dealerID: id.toString(),
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceMin ? priceMin.toString() : "0",
        priceMax: priceMax ? priceMax.toString() : "1000000",
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",

        clarityMin:
          arrClaritys !== null && arrClaritys.length
            ? Math.min(...arrClaritys).toString()
            : "",
        clarityMax:
          arrClaritys !== null && arrClaritys.length
            ? Math.max(...arrClaritys).toString()
            : "",
        cutGradeMin:
          arrCutGrades !== null && arrCutGrades
            ? Math.min(...arrCutGrades).toString()
            : "",
        cutGradeMax:
          arrCutGrades !== null && arrCutGrades
            ? Math.max(...arrCutGrades).toString()
            : "",
        symmetryMin:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.min(...arrSymmetrys).toString()
            : "",
        symmetryMax:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.max(...arrSymmetrys).toString()
            : "",
        polishMin:
          arrPolishs !== null && arrPolishs
            ? Math.min(...arrPolishs).toString()
            : "",
        polishMax:
          arrPolishs !== null && arrPolishs
            ? Math.max(...arrPolishs).toString()
            : "",

        fluorescenceMin:
          arrFluorescences !== null && arrFluorescences
            ? Math.min(...arrFluorescences).toString()
            : "",
        fluorescenceMax:
          arrFluorescences !== null && arrFluorescences
            ? Math.max(...arrFluorescences).toString()
            : "",

        certificate:
          Certificate && Certificate.length > 0 ? Certificate.toString() : "",
        skuNo: searchSku.toString(),
        pairCode: "",

        pageSize: PageSize.toString(),
        currentPage: PageNo.toString(),
        orderBy:
          column == "cut1"
            ? "Cut1"
            : column == "size1"
            ? "Size1"
            : column == "color1"
            ? "Color1"
            : column == "clarity1"
            ? "Clarity1"
            : column == "cutGrade1"
            ? "CutGrade1"
            : column == "depth1"
            ? "Depth1"
            : column == "tableMeasure1"
            ? "TableMeasure1"
            : column == "polish1"
            ? "Polish1"
            : column == "symmetry1"
            ? "Symmetry1"
            : column == "measurements1"
            ? "Measurements1"
            : column == "certificate1"
            ? "Certificate1"
            : column == "totalPrice"
            ? "TotalPrice"
            : "TotalPrice",
        orderType: type ? type : "ASC",

        detailsLink: "StudDiamond_Detail.aspx",
        did: "",
        depthMin: depthmin ? depthmin.toString() : "",
        depthMax: depthmax ? depthmax.toString() : "",
        tableMin: tablemin ? tablemin.toString() : "",
        tableMax: tablemax ? tablemax.toString() : "",
        caratPriceMin: "",
        caratPriceMax: "",

        cutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
        colorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
        clarityList:
          clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",

        polishList:
          polishVal && polishVal.length > 0 ? polishVal.toString() : "",

        symmetryList:
          symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",

        fluorescenceList:
          fluorescenceVal && fluorescenceVal.length > 0
            ? fluorescenceVal.toString()
            : "",

        themeName: "WhiteTheme",
        countryTaxRate: "",
        countryCode: "",
      };
      setLoading(true);
      if (filter) {
        setFilterLoading(true);
      }
      SelectYourDiamondService.LoadDiamonds(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setMinedList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setSearchSku("");
              setRowDetails(FirstRow);
              handleChooseFirstSetting(FirstRow);
              setLoading(false);
            } else {
              setMinedList([]);
              setSearchSku("");
              setTotalRowCount(0);
              setLoading(false);
              setRowDetails([]);
            }
          } else {
            setMinedList([]);
            setSearchSku("");
            setTotalRowCount(0);
            setLoading(false);
            setRowDetails([]);
          }
          setShowAllContent(false);
          setFilterLoading(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShowAllContent(false);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowAllContent(false);
      setFilterLoading(false);
    }
  };

  function onChange(page, pageSize) {
    let filter = [];
    if (page !== pageNo) {
      setPageNo(page);
      handleGetMinedDiamondList(
        dealerId,
        page,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        filter,
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    let filter = [];
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleGetMinedDiamondList(
        dealerId,
        current,
        pageNewSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        "", // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        filter,
        sortColumnName,
        sortType
      );
    }
  }

  const handleSetRowDetils = (e, record) => {
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    let filter = [];
    setSearchSku(value);
    handleGetMinedDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      "", // Certificate
      value, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter, // isFilter
      sortColumnName,
      sortType
    );
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let filter = [];
    let obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }

    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter,
      column,
      type
    );
  };

  // Certificate Link

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  //

  const handleAfterChange = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      e[0],
      e[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType
    );
    let tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  const handleAfterChangePrice = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      e[0],
      e[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType
    );
    let tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setCertificateDetails({});
    setcertInfoData(initialCertInfoData);
    setcertInfoDataValidation(initialCertInfoDataVal);
  };
  const handleSubmitRequestCert = () => {
    handleGetDiamondLinkFiltersRequestCertificate();
  };

  const handleGetDiamondLinkFiltersRequestCertificate = () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: certificateDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: certificateDetails.dealerID,
      };
      setRequestMoreCertModalLoading(true);
      completeYourRing
        .StudBuilderRequestCertificate(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData(initialCertInfoData);
            setcertInfoDataValidation(initialCertInfoDataVal);
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreCertModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreCertModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreCertModalLoading(false);
    }
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber, comments } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }

    if (!comments) {
      isValid = false;
      certInfoValidation.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
  };

  const openLink = (url) => {
    let domainUrl = url?.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i
    );
    if (domainUrl && domainUrl.length > 1) {
      if (url.includes("https")) {
        window.open(url, "_blank");
      } else if (url.includes("http")) {
        window.open(url, "_blank");
      } else {
        window.open("https://" + url, "_blank");
      }
    } else {
      NotificationManager.error("Please Add Alternate Shopping Cart URL");
    }
  };

  const truncateSku = (sku) => {
    if (sku.length > 10) {
      return sku.substring(0, 10) + "...";
    }
    return sku;
  };

  return (
    <Spin spinning={showAllContent} style={{ minHeight: "50vh" }}>
      {showAllContent == false && (
        <React.Fragment>
          <div className="allprod_section jwlsrch__section app__preview choose__setting p-0">
            <div className="tabs__content">
              <div className="diamond__details">
                <div className="row border__block mb-0">
                  <div className="col-md-9">
                    <div className="row">
                      <Spin spinning={filterloading}>
                        <div className="form__fields filter_inpsection border-0 p-0 mt-3 mb-3 mh-auto">
                          <div className="col-md-12">
                            <div className="row">
                              <Wrapper>
                                <div className="col-lg-3 col-md-6">
                                  <div className="input__block mb-1">
                                    <div className="input__container">
                                      <label>Shape</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality.`}
                                      >
                                        <img src={UnionIcon} alt="" style={{ cursor: 'pointer' , marginTop:'-5px',width:'14px'}}  />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="whole__shapes col-lg-12">
                                  {state.Shape &&
                                    state.Shape.map((item, i) => {
                                      return (
                                        <Fragment>
                                          <div
                                            className={
                                              filterVals.Shape &&
                                              filterVals.Shape.includes(
                                                item.value
                                              )
                                                ? "shapes__block selected"
                                                : "shapes__block"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Shape",
                                                "filter"
                                              )
                                            }
                                          >
                                            <div className="image">
                                              <img src={item.shapeImage} />
                                            </div>
                                            <p>{item.name}</p>
                                          </div>
                                        </Fragment>
                                      );
                                    })}
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <div className="input__container">
                                      <label>Cut</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`Not to be confused with shape, a diamond’ s cut rating tells you how well its proportions interact with light.By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is.Cut grading is usually not available for fancy shapes(any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in .`}
                                      >
                                        <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' , marginTop:'-5px',width:'14px'}} />
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Cut &&
                                      state.Cut.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Cut &&
                                                  filterVals.Cut.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Cut",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <div className="input__container">
                                      <label>Color</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone.`}
                                      >
                                        <img src={UnionIcon} alt=""  style={{ cursor: 'pointer',marginTop:'-5px',width:'14px'}} />
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Color &&
                                      state.Color.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Color &&
                                                  filterVals.Color.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Color",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <div className="input__container">
                                      <label>Clarity</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`A diamond’s clarity refers to the tiny traces of natural elements that are trapped inside the stone. 99% of diamonds contain inclusions or flaws. You do not need a flawless diamond - they are very rare and expensive - but you want to look for one that is perfect to the naked eye. Depending on the shape of the diamond, the sweet spot for clarity is usually between VVS2 to SI1.`}
                                      >
                                        <img src={UnionIcon} alt=""  style={{ cursor: 'pointer',marginTop:'-5px',width:'14px'}} />
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Clarity &&
                                      state.Clarity.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Clarity &&
                                                  filterVals.Clarity.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Clarity",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                              </Wrapper>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv">
                                  <div className="input__container">
                                    <label> {"Carats"} </label>
                                    <Tooltip
                                      placement="rightTop"
                                      title={`Carat is a unit of measurement to determine a diamond’s weight. Typically, a higher carat weight means a larger looking diamond, but that is not always the case. Look for the mm measurements of the diamond to determine its visible size.`}
                                    >
                                      <img src={UnionIcon} alt=""  style={{ cursor: 'pointer',marginTop:'2px' ,width:'14px'}} />
                                    </Tooltip>
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <Slider
                                      range
                                      value={caratSlideValue}
                                      min={state.CaratMin}
                                      max={state.CaratMax}
                                      step={0.01}
                                      onChange={(e) => {
                                        handleFilterChangeCaratVal(e);
                                      }}
                                      onAfterChange={(e) => {
                                        handleAfterChange(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[0].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 0);
                                          }}
                                          onBlur={handleBlur}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[1].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 1);
                                          }}
                                          onBlur={handleBlur}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv">
                                  <div className="input__container">
                                      <label>Price Range</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`This refers to different types of Price to filter and select the appropriate ring as per your requirements. Look for the best suit price of your chosen ring.`}
                                      >
                                        <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' ,marginTop:'2px',width:'14px'}} />
                                      </Tooltip>
                                    </div>
                                  <div className="col-md-12 mt-3">
                                    <Slider
                                      range
                                      value={priceValue}
                                      min={state.PriceMin}
                                      max={state.PriceMax}
                                      onChange={(e) => {
                                        handleFilterChangePriceVal(e);
                                      }}
                                      onAfterChange={(e) => {
                                        handleAfterChangePrice(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[0]
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 0);
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[1]
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 1);
                                          }}
                                          onBlur={handleBlur}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Wrapper>
                                {isAdvance && (
                                  <div className="col-md-12 mt-2">
                                    <div className="input__block d-flex align-item-center">
                                      <button
                                        className="primary-btn mr-1"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          padding: "0px",
                                        }}
                                        onClick={() =>
                                          setShowAdvanced(!showAdvanced)
                                        }
                                      >
                                        {showAdvanced ? "-" : "+"}
                                      </button>
                                      <label>Advanced</label>
                                    </div>
                                  </div>
                                )}
                              </Wrapper>
                              {showAdvanced == true ? (
                                <div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__block mb-1">
                                        <div className="input__container">
                                          <label>Polish</label>
                                          <Tooltip
                                            placement="rightTop"
                                            title={`Polish describes how smooth the surface of a diamond is. Aim for an Excellent or Very Good polish rating.`}
                                          >
                                            <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' ,marginTop:'-5px',width:'14px'}} />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Polish &&
                                        state.Polish.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Polish &&
                                                    filterVals.Polish.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Polish",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                          <label>Fluorescence</label>
                                          <Tooltip
                                            placement="rightTop"
                                            title={`Fluorescence tells you how a diamond responds to ultraviolet light - does it glow under a black light? Diamonds with no fluorescence are generally priced higher on the market, but it is rare for fluorescence to have any visual impact on the diamond; some fluorescence can even enhance the look of the stone. Our site recommends searching for diamonds with none to medium fluorescence, and keeping open the option of strong fluorescence for additional value.`}
                                          >
                                            <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' ,marginTop:'-5px',width:'14px'}} />
                                          </Tooltip>
                                        </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Fluorescence &&
                                        state.Fluorescence.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Fluorescence &&
                                                    filterVals.Fluorescence.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Fluorescence",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                          <label>Symmetry</label>
                                          <Tooltip
                                            placement="rightTop"
                                            title={`Symmetry describes how symmetrical the diamond is cut all the way around, which is a contributing factor to a diamond’s sparkle and brilliance. Aim for an Excellent or Very Good symmetry rating for round brilliant shapes, and Excellent to Good for fancy shapes.`}
                                          >
                                            <img src={UnionIcon} alt=""  style={{ cursor: 'pointer',marginTop:'-5px',width:'14px'}} />
                                          </Tooltip>
                                        </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Symmetry &&
                                        state.Symmetry.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Symmetry &&
                                                    filterVals.Symmetry.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Symmetry",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="input__block mb-1">
                                        <div className="input__container">
                                          <label>Depth</label>
                                          <Tooltip
                                            placement="rightTop"
                                            title={`Depth percentage is the height of the diamond measured from the culet to the table, divided by the width of the diamond. The lower the depth %, the larger the diamond will appear (given the same weight), but if this number is too low then the brilliance of the diamond will be sacrificed. The depth percentage is one of the elements that determines the Cut grading.`}
                                          >
                                            <img src={UnionIcon} alt=""  style={{ cursor: 'pointer', marginTop:'-5px',width:'14px'}} />
                                          </Tooltip>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <input
                                              type="number"
                                              value={filterVals.DepthMin}
                                              onChange={(e) => {
                                                handleLabGrownFilterChange(
                                                  e.target.value,
                                                  "DepthMin",
                                                  "input"
                                                );
                                              }}
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                          <div className="col-md-6 mobile__topspace">
                                            <input
                                              type="number"
                                              value={filterVals.DepthMax}
                                              onChange={(e) =>
                                                handleLabGrownFilterChange(
                                                  e.target.value,
                                                  "DepthMax",
                                                  "input"
                                                )
                                              }
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="input__block">
                                        <div className="input__container">
                                          <label>Table</label>
                                          <Tooltip
                                            placement="rightTop"
                                            title={`Table percentage is the width of a diamond’s largest facet the table divided by its overall width. It tells you how big the “face” of a diamond is.`}
                                          >
                                            <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' ,marginTop:'-5px',width:'14px'}} />
                                          </Tooltip>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <input
                                              type="number"
                                              value={filterVals.TableMin}
                                              onChange={(e) =>
                                                handleLabGrownFilterChange(
                                                  e.target.value,
                                                  "TableMin",
                                                  "input"
                                                )
                                              }
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                          <div className="col-md-6 mobile__topspace">
                                            <input
                                              type="number"
                                              value={filterVals.TableMax}
                                              onChange={(e) =>
                                                handleLabGrownFilterChange(
                                                  e.target.value,
                                                  "TableMax",
                                                  "input"
                                                )
                                              }
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="input__block">
                                        <label>Certificates</label>
                                        <Select
                                          className="border__grey"
                                          placeholder="All Certificates"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            onChangeMultiDrop(e, "Certificates")
                                          }
                                          showSearch
                                          showArrow
                                          value={filterVals.Certificates}
                                        >
                                          <Option value="all">
                                            All Certificates
                                          </Option>
                                          {state.Certificates.map((item) => {
                                            return (
                                              <Option
                                                value={item.value}
                                                disabled={
                                                  item.certificateValue == "%"
                                                    ? false
                                                    : filterVals.disabled
                                                        .Certificates
                                                }
                                              >
                                                {item.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="diamondimage__block">
                      <Spin spinning={loading}>
                        <h3 className="subheading">DIAMOND PREVIEW</h3>
                        {rowDetails.length == undefined ? (
                          <>
                            <div className="whole__block">
                              <div className="image__block">
                                <img
                                  src={
                                    rowDetails.bigImageFileName
                                      ? rowDetails.bigImageFileName
                                      : roundDiamond
                                  }
                                />
                                <p>
                                  Stock Number:
                                  <span
                                    className="sku-stock-no"
                                    title={rowDetails.dealerInventoryNo1 || ""}
                                  >
                                    {truncateSku(
                                      rowDetails.dealerInventoryNo1 || ""
                                    )}
                                  </span>
                                </p>
                              </div>

                              <div className="image__block">
                                <img
                                  src={
                                    rowDetails.bigImageFileName
                                      ? rowDetails.bigImageFileName
                                      : roundDiamond
                                  }
                                />
                                <p>
                                  Stock Number:
                                  <span
                                    className="sku-stock-no"
                                    title={rowDetails.dealerInventoryNo2 || ""}
                                  >
                                    {truncateSku(
                                      rowDetails.dealerInventoryNo2 || ""
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <h4 className="subheading">
                              {myOptionsData.showCallForPrice == true ? (
                                "Call For Price"
                              ) : (
                                <>
                                  {rowDetails.totalPrice == "0" ? (
                                    "Call For Price"
                                  ) : (
                                    <span className="subheading">
                                      {rowDetails?.currencycode ?? ""}{" "}
                                      {currencySymbol}
                                      {parseInt(rowDetails.totalPrice)
                                        .toFixed()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  )}
                                </>
                              )}
                            </h4>
                            <div className="mb-3">
                              {myOptionsData.showOnlyDiamondSearch == false ? (
                                <button
                                  className="primary-btn"
                                  onClick={() =>
                                    handleChooseSetting(rowDetails)
                                  }
                                  style={{
                                    background: `${varColorObj.callToActionColor}`,
                                    color: `${
                                      isLightButtonColor ? "#3b4452" : "#ffffff"
                                    }`,
                                  }}
                                >
                                  Add To Stud
                                </button>
                              ) : null}
                            </div>

                            {myOptionsData.showViewCartButton == true ? (
                              <div className="mb-3">
                                <button
                                  onClick={() =>
                                    openLink(myOptionsData.ShoppingCartURL)
                                  }
                                  className="primary-btn"
                                  style={{
                                    background: `${varColorObj.callToActionColor}`,
                                    color: `${
                                      isLightButtonColor ? "#3b4452" : "#ffffff"
                                    }`,
                                  }}
                                >
                                  View Cart
                                </button>
                              </div>
                            ) : null}

                            <div className="mb-3">
                              <button
                                onClick={() =>
                                  handleCompleteEarningDiamond(rowDetails)
                                }
                                className="primary-btn"
                                style={{
                                  background: `${varColorObj.callToActionColor}`,
                                  color: `${
                                    isLightButtonColor ? "#3b4452" : "#ffffff"
                                  }`,
                                }}
                              >
                                View Details
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={NoPreview} />
                            <p>No Results Found </p>

                            <p>Use The Form Below</p>
                          </>
                        )}
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
              {minedList.length > 0 ? (
                <div className="col-md-12">
                  <div className="filter_section p-0">
                    <div className="col-md-12 mt-4">
                      <div className="filt_optndiv">
                        <div className="gview__div">
                          <span className="font__bold mr-2 similar__text">
                            {TotalRowCount} Similar Diamonds
                          </span>
                        </div>
                        {myOptionsData.showOnlyDiamondSearch == false && (
                          <Search
                            placeholder="Search Diamond Stock"
                            onSearch={(e) => handleSearchDiamond(e)}
                            allowClear
                            style={{ width: 250 }}
                          />
                        )}
                      </div>
                    </div>
                    <Wrapper>
                      {viewItem === "grid" && (
                        <Spin spinning={loading}>
                          <div className="col-md-12">
                            <div className="prod__section">
                              <div className="row">
                                {minedList.length > 0 ? (
                                  minedList.map((item, i) => {
                                    return (
                                      <div className="col-lg-3 col-md-6 mb-3">
                                        <div className="prod__maindiv">
                                          <div className="display-block chkbx__div">
                                            <Checkbox
                                              name="checkbox"
                                              checkedChildren="Yes"
                                              unCheckedChildren="No"
                                              checked={
                                                item.checkbox == true
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleOnChange(
                                                  item,
                                                  e.target.checked,
                                                  "checkbox"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="prodimg__div">
                                            <img
                                              src={item.imageFileName}
                                              alt=""
                                            />
                                          </div>
                                          <div className="details__div">
                                            <span className="style__txt">
                                              {"Style No"}: {item.intDiamondId}
                                            </span>
                                            <span className="price__txt">
                                              {item?.currencycode ?? ""}
                                              {currencySymbol}
                                              {Number(
                                                item.realPrice
                                              ).toLocaleString("en-US")}
                                            </span>
                                          </div>
                                          <div className="display-block editdel__div">
                                            <div
                                              onClick={() => {
                                                handleOpenVideoImageModal(
                                                  item,
                                                  true
                                                );
                                              }}
                                            >
                                              <img src={Video_icon} alt="" />
                                            </div>
                                          </div>
                                          {!productChange[item.intDiamondId] ? (
                                            <div
                                              className="info__div"
                                              onClick={() =>
                                                handleProductChangeOpen(
                                                  item.intDiamondId
                                                )
                                              }
                                            >
                                              <img src={Info_icon} alt="" />
                                            </div>
                                          ) : (
                                            <div className="descr__div">
                                              <div className="overlay__block">
                                                <p>
                                                  <span> {"Style No"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {item.intDiamondId}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span>
                                                    {" "}
                                                    {"GFinventory No"}{" "}
                                                  </span>
                                                  <span>:</span>
                                                  <span>
                                                    #{item.gfInventoryID}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span>
                                                    {" "}
                                                    {"Product Name"}{" "}
                                                  </span>
                                                  <span>:</span>
                                                  <span>
                                                    {item.productName}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"Category"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {" "}
                                                    {item.categoryName}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"Collection"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {item.collectionName}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"Vendor Name"} </span>
                                                  <span>:</span>
                                                  <span>{item.vendorName}</span>
                                                </p>
                                                <p>
                                                  <span>
                                                    {" "}
                                                    {"Wholesale Price"}{" "}
                                                  </span>
                                                  <span>:</span>
                                                  <span>
                                                    ${item.wholesaleprice}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span>
                                                    {" "}
                                                    {"Retail Price"}{" "}
                                                  </span>
                                                  <span>:</span>
                                                  <span>
                                                    ${item.retailPrice}
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className="close__div"
                                                onClick={() =>
                                                  handleProductChangeClose(
                                                    item.intDiamondId
                                                  )
                                                }
                                              >
                                                <img src={Close} alt="" />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <span className="mr-2">
                                    No items found{" "}
                                    <span className="linkText">reset</span> your
                                    filters
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </Spin>
                      )}
                    </Wrapper>
                    {viewItem === "list" && (
                      <Spin spinning={loading}>
                        <div className="col-md-12 mt-4">
                          <div className="lviewprod__tbl">
                            <Table
                              columns={columns}
                              onChange={handleTableASCDES}
                              onRow={(record, recordIndex) => ({
                                onClick: (event) => {
                                  handleSetRowDetils(event, record);
                                },
                              })}
                              rowClassName={(record) =>
                                record.intDiamondId == rowDetails.intDiamondId
                                  ? "row-active"
                                  : ""
                              }
                              dataSource={minedList}
                              scroll={{ x: 600, y: 500 }}
                              pagination={false}
                            />
                          </div>
                          <div className="table__bottom__btn single__item">
                            <div>
                              <Pagination
                                current={pageNo}
                                pageSize={pageSize}
                                total={TotalRowCount}
                                onChange={onChange}
                                onShowSizeChange={onShowSizeChange}
                                showSizeChanger="true"
                              />
                            </div>
                          </div>
                        </div>
                      </Spin>
                    )}
                  </div>
                </div>
              ) : (
                <Spin spinning={loading}>
                  <h5 className="subheading mt-3">
                    {" "}
                    No Diamonds Listed In That Range{" "}
                  </h5>
                </Spin>
              )}
            </div>
          </div>

          <Modal
            className="modalconsupld__section"
            style={{ top: 20 }}
            title={"Video"}
            width={500}
            visible={videoImageModal}
            onCancel={() => handleCloseVideoImageModal()}
            footer={[
              <Button onClick={() => handleCloseVideoImageModal()}>
                {"Cancel"}
              </Button>,
            ]}
          >
            <div className="col-lg-12">
              <div className="modal__body">
                <div className="col-lg-12">
                  <div className="form__fields border-0 p-0">
                    <div className="row">
                      {isVideo ? (
                        selectedRowDetails.videoFileName ? (
                          <iframe
                            width="100%"
                            height="450"
                            title="diamond-video"
                            className="VideoIframe"
                            src={selectedRowDetails.videoFileName}
                          ></iframe>
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                      ) : selectedRowDetails.imageFileName ? (
                        <img
                          src={selectedRowDetails.imageFileName}
                          alt="img-diamond"
                        />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            title="Request Certificate"
            visible={requestCertModal}
            style={{ top: 20 }}
            className="request_info"
            onCancel={closeCertInfoModal}
            footer={[
              <Button key="back" onClick={closeCertInfoModal}>
                Cancel
              </Button>,
              <Button
                key="submit"
                loading={requestCertModalLoading}
                type="primary"
                onClick={handleSubmitRequestCert}
              >
                Request
              </Button>,
            ]}
          >
            <div className="row">
              <div class="col-md-12">
                <div className="table__block">
                  <div className="row p-0 border-0 form__fields mh-auto">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>Name</label>
                        <input
                          type="text"
                          value={certInfoData.name}
                          onChange={(e) =>
                            handleCertInfoChange(e, "name", "input")
                          }
                        />
                        <div>
                          {certInfoValidation.nameVal && (
                            <p className="error-color-red">
                              {certInfoValidation.nameVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>E-Mail Address</label>
                        <input
                          type="text"
                          value={certInfoData.email}
                          onChange={(e) =>
                            handleCertInfoChange(e, "email", "input")
                          }
                        />
                        <div>
                          {certInfoValidation.emailVal && (
                            <p className="error-color-red">
                              {certInfoValidation.emailVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          value={certInfoData.phoneNumber}
                          onChange={(e) =>
                            handleCertInfoChange(e, "phoneNumber", "input")
                          }
                        />
                        <div>
                          {certInfoValidation.phoneNumberVal && (
                            <p className="error-color-red">
                              {certInfoValidation.phoneNumberVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input__block">
                        <label>Comments</label>
                        <textarea
                          value={certInfoData.comments}
                          onChange={(e) =>
                            handleCertInfoChange(e, "comments", "input")
                          }
                        >
                          <div>
                            {certInfoValidation.commentsVal && (
                              <p className="error-color-red">
                                {certInfoValidation.commentsVal}
                              </p>
                            )}
                          </div>
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </Spin>
  );
};

export default MinedDiamond;
