let config = {
  AppsAPIURL: "https://jc2-apps-api.stage.jewelcloud.com/api",
  AdminAPIURL: "https://jc2-admin-api.stage.jewelcloud.com/api",
  DealerAPIURL: "https://jc2-dlr-api.stage.jewelcloud.com/api",
  // AdminAPIURL: "https://jc2-admin-api.mgr.brainvire.net/api",
  // AppsAPIURL: "https://jc2-apps-api.mgr.brainvire.net/api",
  // DealerAPIURL: "https://jc2-dlr-api.mgr.jewelcloud.com/api",
};

export default config;
