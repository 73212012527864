import {
  SET_ACTIVE_TAB,
  SWAP_TABS,
  ADD_SETTING_VIEW_EDIT,
  REMOVE_DIAMOND_VIEW_EDIT,
} from "../../actions/header/types";
import DiamondIcon from "../../assets/images/icons/diamond__icon.svg";
const initialState = {
  activeTab: "selectYourDiamond",
  tabDetails: [
    {
      tabName: "Select Your Diamond",
      tabValue: "selectYourDiamond",
      imgSrc: DiamondIcon,
    },
  ],
  isDiamondViewEdit: false,
  isSettingViewEdit: false,
};

export default function handleHeader(headerState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return {
        ...headerState,
        activeTab: payload,
      };
    case SWAP_TABS:
      return {
        ...headerState,
        tabDetails: payload,
      };

    case ADD_SETTING_VIEW_EDIT:
      return {
        ...headerState,
        isSettingViewEdit: true,
      };

    case REMOVE_DIAMOND_VIEW_EDIT:
      let diamondTabDetails = headerState.tabDetails;
      for (let i = 0; i < diamondTabDetails.length; i++) {
        if (diamondTabDetails[i].tabValue == "selectYourDiamond") {
          diamondTabDetails[i].viewEdit = null;
        }
      }
      return {
        ...headerState,
        isDiamondViewEdit: false,
        tabDetails: diamondTabDetails,
      };

    default:
      return headerState;
  }
}
