import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Input,
	Select,
	Table,
	Button,
	Modal,
	Tooltip,
	Spin,
	Pagination,
} from "antd";
import Eye_icon from "../../assets/images/eye__icon.svg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import { Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";
import IconPrint from "../../assets/images/choose-setting/IconPrint.svg";

import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";

import GIA from "../../assets/images/choose-setting/gia.jpg";
import AGS from "../../assets/images/choose-setting/ags.jpg";
import IGI from "../../assets/images/choose-setting/igi.jpg";
import AllImg from "../../assets/images/choose-setting/img5.jpg";
import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";
import FaceBookLike from "../../assets/images/icons/facebook-like.jpg";
import ChooseSettingServices from "../../services/choose-settings.service";
import completeYourRing from "../../services/complete-your-ring.service";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import {
	handleEditViewShow,
	handleCompleteEarningTwoDiamond,
} from "../../actions/chooseYourSetting/chooseYourSettingActions";
import { handleActiveTab } from "../../actions/header/headerActions";
import { handleViewAddToStudPageDetails } from "../../actions/selectYourDiamond/selectYourDiamond";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const initialDiamondInputData = {
	name: "",
	email: "",
	phone: "",
	comments: "",
};

const initialDiamondInputDataVal = {
	nameval: "",
	emailval: "",
	phoneval: "",
};

const initialDiamondInputData1 = {
	name1: "",
	email1: "",
	phone1: "",
	comments1: "",
};

const initialDiamondInputDataVal1 = {
	nameval: "",
	emailval: "",
	phoneval: "",
};

const initialDropAHintData = {
	name: "",
	email: "",
	recipientName: "",
	recipientEmail: "",
	giftReason: "",
	giftDeadline: "",
	personalMessage: "",
};

const initialDropAHintDataVal = {
	nameval: "",
	emailval: "",
	recipientNameval: "",
	recipientEmailval: "",
	giftReasonval: "",
	giftDeadlineval: "",
	personalMessageval: "",
};

const initialRequestMoreInfoData = {
	name: "",
	email: "",
	phoneNumber: "",
	contactPreference: "byEmail",
	personalMessage: "",
};

const initialRequestMoreInfoDataVal = {
	nameval: "",
	emailval: "",
	phoneNumberval: "",
	contactPreferenceval: "",
	personalMessageval: "",
};

const initialEmailAFriendInputData = {
	name: "",
	email: "",
	friendName: "",
	friendEmail: "",
	personalMessage: "",
};

const initialEmailAFriendInputDataVal = {
	nameval: "",
	emailval: "",
	friendNameval: "",
	friendEmailval: "",
	personalMessageval: "",
};

const initialScheduleViewingData = {
	name: "",
	email: "",
	phoneNumber: "",
	location: "",
	appointmentDate: "",
	appointmentTime: "",
	address: "",
	message: "",
};

const initialScheduleViewingDataVal = {
	nameval: "",
	emailVal: "",
	phoneNumberVal: "",
	locationVal: "",
	appointmentDateVal: "",
	appointmentTimeVal: "",
	addressVal: "",
	messageVal: "",
};

export const ChooseSettingItemDetails = ({}) => {
	const { Search } = Input;
	const search = useLocation().search;
	const dispatch = useDispatch();
	const loginDetails = useSelector((state) => state.loginDetailsReducer);
	const [internalUseDataValidation, setInternalUseDataValidation] = useState({
		passwordVal: "",
	});
	const initialOptionsData = {
		studbuilderLinkURL: "",
		internalUseLink: false,
		showOnlyDiamondSearch: false,
		showDealerDiamondsSKU: false,
		showScheduleViewing: false,
		showAddress: false,
		showCallForPrice: false,
		applyGstTax: false,
		gstTaxValue: "",
		showPinterestShare: false,
		showTwitterShare: false,
		showFacebookShare: false,
		showFacebookLike: false,
		showAddToCartButton: false,
		ShoppingCartURL: "",
		showViewCartButton: true,
		ShowInstagramPlus: false,
	};
	const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
	const [displayImage, setDisplayImage] = useState("");
	const [count, SetCount] = useState(0);
	const [tableLoading, setTableLoading] = useState(false);
	const [dataLoding, setDataLoding] = useState(false);
	const [similarToogle, setSimilarToogle] = useState(true);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [searchedValue, setSearchedValue] = useState("");

	/* Color Individually Start */

	const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
	const [isLightButtonColor, setIsLightButtonColor] = useState(false);
	const [isLightHoverColor, setIsLightHoverColor] = useState(false);

	const previewcolor = useSelector((state) => state.previewColorReducer);
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});
	const [selectedDiamondDetails, setSelectedDiamondDetails] = useState(null);
	const [confirmScheduleModal, setConfirmScheduleModal] = useState(false);

	// Modal Functions
	const [requestMoreModal, setRequestMoreModal] = useState(false);
	const [showDropModal, setShowDropModal] = useState(false);
	const [emailFriendModal, setEmailFriendModal] = useState(false);
	const [scheduleViewing, setOpenScheduleViewing] = useState(false);
	const [isDiamondView, setIsDiamondView] = useState(false);
	const [isDiamondView1, setIsDiamondView1] = useState(false);
	const [isModalVisible6, setIsModalVisible6] = useState(false);
	const [productSettingItemList, setProductSettingItemList] = useState([]);
	const [currencySymbol, setCurrencySymbol] = useState("");
	const [locationDetails, setLocationDetails] = useState([]);
	const [productSettingTableItemList, setProductSettingTableItemList] =
		useState([]);
	const [internalUseModal, setInternalUseModal] = useState(false);
	const [showDealerInfo, setShowDealerInfo] = useState();
	const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
	const [dealerInfo, setDealerInfo] = useState({});
	const [dealerInfo1, setDealerInfo1] = useState({});
	const [locationData, setLocationData] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [internalUseData, setInternalUseData] = useState({
		password: "",
	});
	const [loadingTable, setLoadingTable] = useState(false);

	const [dataItem, setDataItem] = useState({
		dealerId: "",
		sku: "",
		linkurl: "",
		shape: "",
		diamondshape: "",
		diamonddealer: "",
		diamondinventory: "",
		diamondinventory1: "",
	});

	const [diamondViewInputData, setDiamondViewInputData] = useState(
		initialDiamondInputData
	);
	const [diamondViewInputDataValidation, setDiamondViewInputDataValidation] =
		useState(initialDiamondInputDataVal);
	const [diamondViewInputData1, setDiamondViewInputData1] = useState(
		initialDiamondInputData1
	);
	const [diamondViewInputDataValidation1, setDiamondViewInputDataValidation1] =
		useState(initialDiamondInputDataVal1);
	const [dropHintInputData, setDropHintInputData] =
		useState(initialDropAHintData);
	const [dropHintInputDataValidation, setDropHintInputDataValidation] =
		useState(initialDropAHintDataVal);
	const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState(
		initialRequestMoreInfoData
	);
	const [
		requestMoreInfoInputDataValidation,
		setRequestMoreInfoInputDataValidation,
	] = useState(initialRequestMoreInfoDataVal);
	const [emailFriendInputData, setEmailFriendInputData] = useState(
		initialEmailAFriendInputData
	);
	const [emailFriendInputDataValidation, setEmailFriendInputDataValidation] =
		useState(initialEmailAFriendInputDataVal);
	const [scheduleViewingInputData, setScheduleViewingInputData] = useState(
		initialScheduleViewingData
	);
	const [
		scheduleViewingInputDataValidation,
		setScheduleViewingInputDataValidation,
	] = useState(initialScheduleViewingDataVal);

	const paramdealerId = new URLSearchParams(search).get("DealerID");
	const paramdealerID1 = new URLSearchParams(search).get("dealerID1");
	const diamondId =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).diamondID1;
	const paramDetailLinkURL =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).detailLinkURL;
	const socialpageName =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).pageName;
	const paramCut =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).cut1;
	const socialshapeFilters =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).cut1;
	const socialtxtCutlet =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).cutGrade1;
	const socialtxtClarity =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).clarity1;
	const sociaColorPriority =
		localStorage.getItem("selectedDimaondDetails") &&
		JSON.parse(localStorage.getItem("selectedDimaondDetails")).color1;

	useLayoutEffect(() => {
		if (paramdealerId) {
			if (paramdealerId) {
				handleGetColorsData(paramdealerId);
			}
		}
	}, [previewcolor.toogle]);

	const handleGetColorsData = async (id) => {
		let inputData = {
			dealerID: Number(id),
			moduleName: "StudBuilder",
			hoverEffect: "",
			columnHeaderAccent: "",
			linkColor: "",
			actionButton: "",
		};
		try {
			await colorPanelService
				.GetHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					let initialColorObj =
						response.data.responseData.initialHoverEffectList[0];
					let newColorObj =
						response.data.responseData.ringBuilder_HoverEffect_List[0];
					let colorObj = {
						hoverEffect: "",
						columnHeaderAccent: "",
						linkColor: "",
						actionButton: "",
					};

					if (msg == "Success") {
						if (initialColorObj) {
							var tempobj = colorObj;
							tempobj.hoverEffect = newColorObj
								? newColorObj.hoverEffect == ""
									? initialColorObj.hoverEffect
									: newColorObj.hoverEffect
								: initialColorObj.hoverEffect;
							tempobj.columnHeaderAccent = newColorObj
								? newColorObj.columnHeaderAccent == ""
									? initialColorObj.columnHeaderAccent
									: newColorObj.columnHeaderAccent
								: initialColorObj.columnHeaderAccent;
							tempobj.linkColor = newColorObj
								? newColorObj.linkColor == ""
									? initialColorObj.linkColor
									: newColorObj.linkColor
								: initialColorObj.linkColor;
							tempobj.actionButton = newColorObj
								? newColorObj.actionButton == ""
									? initialColorObj.actionButton
									: newColorObj.actionButton
								: initialColorObj.actionButton;
							if (tempobj) {
								setVarColorObj({
									...varColorObj,
									callToActionColor: tempobj.actionButton,
									hoverColor: tempobj.hoverEffect,
									columnHeaderAccentColor: tempobj.columnHeaderAccent,
									linkColor: tempobj.linkColor,
								});
								lightOrDark(tempobj.columnHeaderAccent, "header");
								lightOrDark(tempobj.callToActionColor, "button");
								lightOrDark(tempobj.hoverColor, "hover");
							} else {
								setVarColorObj({
									...varColorObj,
									callToActionColor: initialColorObj.actionButton,
									hoverColor: initialColorObj.hoverEffect,
									columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
									linkColor: initialColorObj.linkColor,
								});
								lightOrDark(initialColorObj.columnHeaderAccent, "header");
								lightOrDark(initialColorObj.callToActionColor, "button");
								lightOrDark(initialColorObj.hoverColor, "hover");
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	function lightOrDark(color, type) {
		// Variables for red, green, blue values
		var r, g, b, hsp;

		if (color) {
			// Check the format of the color, HEX or RGB?
			if (color.match(/^rgb/)) {
				// If RGB --> store the red, green, blue values in separate variables
				color = color.match(
					/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
				);

				r = color[1];
				g = color[2];
				b = color[3];
			} else {
				// If hex --> Convert it to RGB: http://gist.github.com/983661
				color = +(
					"0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
				);

				r = color >> 16;
				g = (color >> 8) & 255;
				b = color & 255;
			}

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

			// Using the HSP value, determine whether the color is light or dark
			// if hsp> 127.5 then it is dark
			if (hsp > 127.5) {
				if (type == "header") {
					setIsLightHeaderColor(true);
				} else if (type == "button") {
					setIsLightButtonColor(true);
				} else {
					setIsLightHoverColor(true);
				}
			} else {
				if (type == "header") {
					setIsLightHeaderColor(false);
				} else if (type == "button") {
					setIsLightButtonColor(false);
				} else {
					setIsLightHoverColor(false);
				}
			}
		}
	}

	const Wrapper = styled.div`
		& .whole__shapes .shapes__block .image:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block:hover .image {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block .image .selected {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .whole__shapes .shapes__block.selected .image {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .img__block .imgselct__div:hover {
			border-bottom: 2px solid ${varColorObj.hoverColor};
		}

		& .img__block .imgselct__div.selected {
			border-bottom: 2px solid ${varColorObj.linkColor};
		}

		& .selection__block .selection__box:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background-color: ${varColorObj.hoverColor};
		}
		& .selection__block .selection__box.selected {
			border: 1px solid ${varColorObj.linkColor};
			background-color: ${varColorObj.linkColor};
		}

		& .ant-table-thead > tr > th {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			font-family: "LexendDeca-Semibold";
			height: 50px;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
			line-height: 14px;
		}

		& .ant-btn-primary {
			background: ${varColorObj.callToActionColor} !important;
			border-color: ${varColorObj.callToActionColor} !important;
		}
		& .ant-btn-primary:hover {
			background: ${varColorObj.hoverColor} !important;
			border-color: ${varColorObj.hoverColor} !important;
		}

		& .primary-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .primary-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}
		& .ant-input-search-button {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}

		& .ant-input-search-button:hover {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}
		& .linkText {
			color: ${varColorObj.linkColor};
			text-decoration: none;
			cursor: pointer;
		}

		& .linkText:hover {
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover img {
			filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
				brightness(93%) contrast(89%);
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover span {
			color: ${varColorObj.hoverColor};
		}

		& .ant-pagination-item-active {
			border: 1px solid ${varColorObj.linkColor} !important;
		}

		& .ant-pagination-item:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}
		& .nav-tabs .nav-link.active {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .ant-input-search-button {
			color: rgba(0, 0, 0, 0.45) !important;
		}
		& .ant-input-search-button:hover {
			color: rgba(0, 0, 0, 0.45) !important;
		}
	`;

	/* Color Individually Ends */

	useEffect(() => {
		if (paramdealerId) {
			if (paramdealerId) {
				handleGetstudBuilderOptiondetails(paramdealerId);
			}
		}
	}, []);

	const handleGetstudBuilderOptiondetails = (id) => {
		let inputData = {
			dealerID: id,
		};

		try {
			SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];
					if (message == "Success") {
						if (responseData) {
							if (response.data.responseData.length > 0) {
								setMyOptionsData((prevState) => ({
									...prevState,
									studbuilderLinkURL: responseData.studBuilderURL,
									internalUseLink: responseData.internalLinkSB,
									showOnlyDiamondSearch: responseData.onlyDiamondSearch,
									showDealerDiamondsSKU: responseData.stud_ShowSKU,
									showScheduleViewing: responseData.showScheduleView,
									showAddress: responseData.showAddresses,
									showCallForPrice: responseData.callForPricestud,
									applyGstTax: responseData.showGstPrice,
									gstTaxValue: responseData.taxRate,
									showPinterestShare: responseData.showPinterestShare,
									showTwitterShare: responseData.showTwitterShare,
									showFacebookShare: responseData.showFacebookShare,
									showFacebookLike: responseData.showFacebookLike,
									showAddToCartButton: responseData.showAddToCartButtonStud,
									ShoppingCartURL: responseData.diamondstudshoppingurl,
									showViewCartButton: responseData.showViewCartButtonStud,
									ShowInstagramPlus: responseData.showInstagramShare,
								}));
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (paramdealerId) {
			const diamondDetails = {
				shapeFilters: socialshapeFilters,
				txtCutlet: socialtxtCutlet,
				txtClarity: socialtxtClarity,
				intColorPriority: sociaColorPriority,
				pageName: socialpageName,
			};

			setSelectedDiamondDetails(diamondDetails);

			//To fetch selected diamond details
			GetChooseSettingItemDetails(
				paramdealerId,
				"",
				"0",
				"0",
				"0",
				"0",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				diamondId, // chooseYourSetting.selectedRow.diamondID1,
				"",
				"10",
				"1",
				"TotalPrice",
				"Asc",
				paramDetailLinkURL, // chooseYourSetting.selectedRow.detailLinkURL, //
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"WhiteTheme",
				"",
				"",
				"single"
			);
			handleGetDealerLocationData(paramdealerId, paramdealerId);
			GetLocationLoadDetails(paramdealerId, diamondDetails);

			setDataItem((prevState) => ({
				...prevState,
				dealerId: paramdealerId,
				sku: diamondId,
				linkurl: paramDetailLinkURL,
				shape: paramCut,
			}));
		}
	}, [similarToogle]);

	const handleEditDiamond = () => {
		navigate(
			"/Mined" +
				`?DealerLink=${paramdealerId}&tabName=selectYourDiamond&isTab=True&tabkey=Standard`
		);
	};
	const columns = [
		{
			title: "Shape",
			dataIndex: "cut1",
			width: 120,
			render: (item, row) => {
				return (
					<div>
						<div className="stud__img">
							<img
								style={{ width: "20px", height: "20px" }}
								src={row.imageFileName}
							/>
							<span>{" " + row.cut1}</span>
						</div>

						<div className="stud__img">
							<img
								style={{ width: "20px", height: "20px" }}
								src={row.imageFileName}
							/>
							<span>{" " + row.cut2}</span>
						</div>
					</div>
				);
			},
		},

		...(myOptionsData.showDealerDiamondsSKU == true
			? [
					{
						title: "SKU",
						width: 100,
						dataIndex: "intDiamondId",
						render: (item, row) => {
							const style = {
								display: "inline-block",
								maxWidth: "100%",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								cursor: "pointer",
							};
							return (
								<div>
									<Tooltip title={row.dealerInventoryNo1}>
										<div style={style}>{row.dealerInventoryNo1}</div>
									</Tooltip>
									<br />
									<Tooltip title={row.dealerInventoryNo2}>
										<div style={style}>{row.dealerInventoryNo2}</div>
									</Tooltip>
								</div>
							);
						},
					},
			  ]
			: []),

		{
			title: "Carat",
			dataIndex: "size1",
			width: 80,
			render: (item, row) => {
				return (
					<div>
						<span>{row.size1}</span>
						<br></br>
						<span>{row.size2}</span>
					</div>
				);
			},
		},
		{
			title: "Color",
			dataIndex: "color1",
			responsive: ["lg"],
			width: 80,
			render: (item, row) => {
				return (
					<div>
						<span>{row.color1}</span>
						<br></br>
						<span>{row.color2}</span>
					</div>
				);
			},
		},
		{
			title: "Clarity",
			dataIndex: "clarity1",
			width: 80,
			render: (item, row) => {
				return (
					<div>
						<span>{row.clarity1}</span>
						<br></br>
						<span>{row.clarity2}</span>
					</div>
				);
			},
		},
		{
			title: "Cut",
			dataIndex: "cutGrade1",
			responsive: ["lg"],
			width: 100,
			render: (item, row) => {
				return (
					<div>
						<span>{row.cutGrade1}</span>
						<br></br>
						<span>{row.cutGrade2}</span>
					</div>
				);
			},
		},
		{
			title: "Depth",
			dataIndex: "depth1",
			width: 80,
			responsive: ["lg"],
			render: (item, row) => {
				return (
					<div>
						<span>{row.depth1}</span>
						<br></br>
						<span>{row.depth2}</span>
					</div>
				);
			},
		},
		{
			title: "Table",
			dataIndex: "tableMeasure1",
			width: 80,
			responsive: ["lg"],
			render: (item, row) => {
				return (
					<div>
						<span>{row.tableMeasure1}</span>
						<br></br>
						<span>{row.tableMeasure2}</span>
					</div>
				);
			},
		},
		{
			title: "Polish",
			dataIndex: "polish1",
			width: 100,
			responsive: ["lg"],
			render: (item, row) => {
				return (
					<div>
						<span>{row.polish1}</span>
						<br></br>
						<span>{row.polish2}</span>
					</div>
				);
			},
		},
		{
			title: "Sym.",
			dataIndex: "symmetry1",
			width: 120,
			responsive: ["lg"],
			render: (item, row) => {
				return (
					<div>
						<span>{row.symmetry1}</span>
						<br></br>
						<span>{row.symmetry2}</span>
					</div>
				);
			},
		},
		{
			title: "Measure.",
			responsive: ["lg"],
			dataIndex: "measurements1",
			width: 150,
			render: (item, row) => {
				return (
					<div>
						<span>{row.measurements1}</span>
						<br></br>
						<span>{row.measurements2}</span>
					</div>
				);
			},
		},
		{
			title: "Cert.",
			dataIndex: "certificate1",
			responsive: ["lg"],
			width: 80,
			className: "my-hover-pointer linkText",
			render: (item, row) => {
				return (
					<div>
						<span onClick={() => handleOpenCertificate(row.certificateLink)}>
							{row.certificate1}
						</span>
						<br></br>
						<span onClick={() => handleOpenCertificate(row.certificateLink2)}>
							{row.certificate2}
						</span>
					</div>
				);
			},
		},
		{
			title: `Price (${productSettingItemList?.currencycode ?? ""}${
				currencySymbol ?? ""
			})`,
			dataIndex: "totalPrice",
			width: 100,
			render: (item, row) => {
				return myOptionsData.showCallForPrice == true ? (
					<span>Call For Price</span>
				) : (
					<>
						{row.totalPrice == "0" ? (
							<>
								Call <span className="hideInMobile">For Price</span>
							</>
						) : (
							<span>
								{row?.currencycode ?? ""} {row.currencySign}
								{parseInt(row.totalPrice)
									.toFixed()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							</span>
						)}
					</>
				);
			},
		},
		{
			title: "View",
			dataIndex: "",
			width: 100,
			render: (index, row) => (
				<div>
					<div className="action__btns">
						<div className="image__block">
							<img
								src={Eye_icon}
								alt=""
								onClick={() => handleCompleteEarningDiamond(row)}
							/>
						</div>
					</div>
				</div>
			),
		},
	];

	const { Option } = Select;

	const images = [
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
		},
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
		},
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
		},
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
		},
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
		},
		{
			original:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
			thumbnail:
				"https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
		},
	];

	function renderLeftNav(onClick, disabled) {
		return (
			<button
				type="button"
				className="image-gallery-left-nav"
				aria-label="Prev Slide"
				disabled={disabled}
				onClick={onClick}>
				<img src={leftArrow} />
			</button>
		);
	}

	function renderRightNav(onClick, disabled) {
		return (
			<button
				type="button"
				className="image-gallery-right-nav"
				aria-label="Next Slide"
				disabled={disabled}
				onClick={onClick}>
				<img src={rightArrow} />
			</button>
		);
	}
	const handlepasswordchange = (e, name, type) => {
		setInternalUseData({
			...internalUseData,
			[name]: e.target.value,
		});
		setInternalUseDataValidation({
			passwordVal: "",
		});
	};

	const handleValidation = () => {
		const { password } = internalUseData;

		const internalUseDataValidation = {
			passwordVal: "",
		};
		let isValid = true;

		if (!password) {
			isValid = false;
			internalUseDataValidation.passwordVal = "Password is Compulsory";
		}

		setInternalUseDataValidation(internalUseDataValidation);

		return isValid;
	};

	const handleRequestMoreInfoChange = (e, name, type) => {
		setRequestMoreInfoInputData({
			...requestMoreInfoInputData,
			[name]: e.target.value,
		});

		if (name == "name") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "phoneNumber") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				phoneNumberval: "",
			}));
		}
	};
	const handleCancelFullscreen = () => {
		setIsModalVisibleFullscreen(false);
	};

	const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
		useState(false);

	const handleDiamondViewInfoChange = (e, name, type) => {
		setDiamondViewInputData({
			...diamondViewInputData,
			[name]: e.target.value,
		});
		if (e.target.name == "name") {
			setDiamondViewInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (e.target.name == "email") {
			setDiamondViewInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (e.target.name == "phone") {
			setDiamondViewInputDataValidation((prevdata) => ({
				...prevdata,
				phoneval: "",
			}));
		}
	};

	const handleDiamondViewInfoChange1 = (e, name, type) => {
		setDiamondViewInputData1({
			...diamondViewInputData1,
			[name]: e.target.value,
		});
		if (e.target.name == "name1") {
			setDiamondViewInputDataValidation1((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (e.target.name == "email1") {
			setDiamondViewInputDataValidation1((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (e.target.name == "phone1") {
			setDiamondViewInputDataValidation1((prevdata) => ({
				...prevdata,
				phoneval: "",
			}));
		}
	};

	const handleEmailFriendChange = (e, name) => {
		setEmailFriendInputData({
			...emailFriendInputData,
			[name]: e.target.value,
		});

		if (name == "name") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "friendName") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				friendNameval: "",
			}));
		}

		if (name == "friendEmail") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				friendEmailval: "",
			}));
		}

		if (name == "personalMessage") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				personalMessageval: "",
			}));
		}
	};

	const handleScheduleViewingChange = (e, name, type) => {
		if (type == "select") {
			setScheduleViewingInputData({
				...scheduleViewingInputData,
				[name]: e,
			});
			if (name == "location") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					locationVal: "",
				}));
			}

			if (name == "appointmentTime") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					appointmentTimeVal: "",
				}));
			}
		} else {
			setScheduleViewingInputData({
				...scheduleViewingInputData,
				[name]: e.target.value,
			});

			if (name == "name") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					nameval: "",
				}));
			}
			if (name == "email") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					emailVal: "",
				}));
			}
			if (name == "phoneNumber") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					phoneNumberVal: "",
				}));
			}
			if (name == "message") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					messageVal: "",
				}));
			}
			if (name == "appointmentDate") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					appointmentDateVal: "",
				}));
			}
		}
	};

	const handleDropHintChange = (e, name, type) => {
		setDropHintInputData({
			...dropHintInputData,
			[name]: e.target.value,
		});
		if (name == "name") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "recipientName") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				recipientNameval: "",
			}));
		}
		if (name == "recipientEmail") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				recipientEmailval: "",
			}));
		}
		if (name == "giftReason") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				giftReasonval: "",
			}));
		}
		if (name == "personalMessage") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				personalMessageval: "",
			}));
		}
		if (name == "giftDeadline") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				giftDeadlineval: "",
			}));
		}
	};

	const handleValidationDrop = () => {
		const {
			name,
			email,
			recipientName,
			recipientEmail,
			giftReason,
			personalMessage,
			giftDeadline,
		} = dropHintInputData;

		const dropHintInputDataValidation = {
			nameval: "",
			emailval: "",
			recipientNameval: "",
			recipientEmailval: "",
			giftReasonval: "",
			personalMessageval: "",
			giftDeadlineval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			dropHintInputDataValidation.nameval = "Name is Compulsory";
		} else {
			dropHintInputDataValidation.nameval = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			dropHintInputDataValidation.emailval = "Email is Compulsory";
		} else if (!email.match(validRegex)) {
			isValid = false;
			dropHintInputDataValidation.emailval = "Email is Invalid";
		} else {
			dropHintInputDataValidation.emailval = "";
		}

		if (!recipientName.trim()) {
			isValid = false;
			dropHintInputDataValidation.recipientNameval =
				"Recipient's Name is Compulsory";
		} else {
			dropHintInputDataValidation.recipientNameval = "";
		}

		if (!recipientEmail.trim()) {
			isValid = false;
			dropHintInputDataValidation.recipientEmailval =
				"Recipient's E-Mail is Compulsory";
		} else if (!recipientEmail.match(validRegex)) {
			isValid = false;
			dropHintInputDataValidation.recipientEmailval =
				"Recipient's E-Mail is Invalid";
		} else {
			dropHintInputDataValidation.recipientEmailval = "";
		}

		if (!giftReason.trim()) {
			isValid = false;
			dropHintInputDataValidation.giftReasonval = "Reason is Compulsory";
		} else {
			dropHintInputDataValidation.giftReasonval = "";
		}

		if (!personalMessage.trim()) {
			isValid = false;
			dropHintInputDataValidation.personalMessageval =
				"Personal Message is Compulsory";
		} else {
			dropHintInputDataValidation.personalMessageval = "";
		}

		if (!giftDeadline.trim()) {
			isValid = false;
			dropHintInputDataValidation.giftDeadlineval =
				"Gift Deadline is Compulsory";
		} else {
			dropHintInputDataValidation.giftDeadlineval = "";
		}

		setDropHintInputDataValidation(dropHintInputDataValidation);
		return isValid;
	};

	const handleValidationReuestInfo = () => {
		const { name, email, phoneNumber } = requestMoreInfoInputData;

		const requestMoreInfoInputDataValidation = {
			nameval: "",
			emailval: "",
			phoneNumberval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.nameval = "Name is Compulsory";
		} else {
			requestMoreInfoInputDataValidation.nameval = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.emailval = "Email is Compulsory";
		} else if (!email.match(validRegex)) {
			isValid = false;
			requestMoreInfoInputDataValidation.emailval = "Email is Invalid";
		} else {
			requestMoreInfoInputDataValidation.emailval = "";
		}

		if (!phoneNumber.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.phoneNumberval =
				"Phone Number is Compulsory";
		} else {
			requestMoreInfoInputDataValidation.phoneNumberval = "";
		}
		setRequestMoreInfoInputDataValidation(requestMoreInfoInputDataValidation);
		return isValid;
	};

	const handleValidationEmailFriend = () => {
		const { name, email, friendName, friendEmail, personalMessage } =
			emailFriendInputData;

		const emailFriendInputDataValidation = {
			nameval: "",
			emailval: "",
			friendNameval: "",
			friendEmailval: "",
			personalMessageval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			emailFriendInputDataValidation.nameval = "Name is Compulsory";
		} else {
			emailFriendInputDataValidation.nameval = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			emailFriendInputDataValidation.emailval = "Email is Compulsory";
		} else if (!email.match(validRegex)) {
			isValid = false;
			emailFriendInputDataValidation.emailval = "Email is Invalid";
		} else {
			emailFriendInputDataValidation.emailval = "";
		}

		if (!friendName.trim()) {
			isValid = false;
			emailFriendInputDataValidation.friendNameval =
				"Friend's Name is Compulsory";
		} else {
			emailFriendInputDataValidation.friendNameval = "";
		}

		if (!friendEmail.trim()) {
			isValid = false;
			emailFriendInputDataValidation.friendEmailval =
				"Friend's E-Mail is Compulsory";
		} else if (!friendEmail.match(validRegex)) {
			isValid = false;
			emailFriendInputDataValidation.friendEmailval =
				"Friend's E-Mail is Invalid";
		} else {
			emailFriendInputDataValidation.friendEmailval = "";
		}

		if (!personalMessage.trim()) {
			isValid = false;
			emailFriendInputDataValidation.personalMessageval =
				"Personal Message is Compulsory";
		} else {
			emailFriendInputDataValidation.personalMessageval = "";
		}
		setEmailFriendInputDataValidation(emailFriendInputDataValidation);
		return isValid;
	};

	const handleValidationSchedule = () => {
		const {
			name,
			email,
			phoneNumber,
			location,
			appointmentDate,
			appointmentTime,
			address,
			message,
		} = scheduleViewingInputData;

		const scheduleViewingInputDataValidation = {
			nameval: "",
			emailVal: "",
			phoneNumberVal: "",
			locationVal: "",
			appointmentDateVal: "",
			appointmentTimeVal: "",
			addressVal: "",
			messageVal: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			scheduleViewingInputDataValidation.nameval = "Name is Compulsory";
		} else {
			scheduleViewingInputDataValidation.nameval = "";
		}

		if (!location) {
			isValid = false;
			scheduleViewingInputDataValidation.locationVal = "Location is Compulsory";
		}

		if (!appointmentDate) {
			isValid = false;
			scheduleViewingInputDataValidation.appointmentDateVal =
				"Appointment Date is Compulsory";
		}

		if (!appointmentTime) {
			isValid = false;
			scheduleViewingInputDataValidation.appointmentTimeVal =
				"Appointment Time is Compulsory";
		}

		if (!message) {
			isValid = false;
			scheduleViewingInputDataValidation.messageVal = "Message is Compulsory";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email) {
			isValid = false;
			scheduleViewingInputDataValidation.emailVal =
				"Email address is compulsory";
		} else if (!email.match(validRegex)) {
			isValid = false;
			scheduleViewingInputDataValidation.emailVal = "Email address is invalid";
		} else {
			scheduleViewingInputDataValidation.emailVal = "";
		}
		if (!phoneNumber) {
			isValid = false;
			scheduleViewingInputDataValidation.phoneNumberVal =
				"Phone number is compulsory";
		} else if (
			(phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
			(phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
			(phoneNumber.length >= 10 && phoneNumber.includes("+"))
		) {
			scheduleViewingInputDataValidation.phoneNumberVal = "";
		} else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
			isValid = false;
			scheduleViewingInputDataValidation.phoneNumberVal =
				"Phone number is invalid";
		} else {
			scheduleViewingInputDataValidation.phoneNumberVal = "";
		}

		setScheduleViewingInputDataValidation(scheduleViewingInputDataValidation);
		return isValid;
	};

	const handleValidationDiamondView = () => {
		const { name, email, phone } = diamondViewInputData;

		const diamondViewInputDataValidation = {
			nameval: "",
			emailval: "",
			phoneval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			diamondViewInputDataValidation.nameval = "Name is Compulsory";
		} else {
			diamondViewInputDataValidation.nameval = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			diamondViewInputDataValidation.emailval = "Email is Compulsory";
		} else if (!email.match(validRegex)) {
			isValid = false;
			diamondViewInputDataValidation.emailval = "Email is Invalid";
		} else {
			diamondViewInputDataValidation.emailval = "";
		}

		if (!phone.trim()) {
			isValid = false;
			diamondViewInputDataValidation.phoneval = "Phone is Compulsory";
		} else {
			diamondViewInputDataValidation.phoneval = "";
		}
		setDiamondViewInputDataValidation(diamondViewInputDataValidation);
		return isValid;
	};

	const handleSearchCollection = (value) => {
		setSearchedValue(value);
		handleSimilarDiamondList(
			paramdealerId,
			selectedDiamondDetails,
			value,
			1,
			10
		);
	};

	const handleValidationDiamondView1 = () => {
		const { name1, email1, phone1 } = diamondViewInputData1;

		const diamondViewInputDataValidation1 = {
			nameval: "",
			emailval: "",
			phoneval: "",
		};
		let isValid = true;

		if (!name1.trim()) {
			isValid = false;
			diamondViewInputDataValidation1.nameval = "Name is Compulsory";
		} else {
			diamondViewInputDataValidation1.nameval = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email1.trim()) {
			isValid = false;
			diamondViewInputDataValidation1.emailval = "Email is Compulsory";
		} else if (!email1.match(validRegex)) {
			isValid = false;
			diamondViewInputDataValidation1.emailval = "Email is Invalid";
		} else {
			diamondViewInputDataValidation1.emailval = "";
		}

		if (!phone1.trim()) {
			isValid = false;
			diamondViewInputDataValidation1.phoneval = "Phone is Compulsory";
		} else {
			diamondViewInputDataValidation1.phoneval = "";
		}
		setDiamondViewInputDataValidation1(diamondViewInputDataValidation1);
		return isValid;
	};

	const handleSubmitDropHint = () => {
		handleSaveDropHint();
	};

	const handleSaveDropHint = async () => {
		let isValid = handleValidationDrop();

		if (!isValid) {
			setShowDropModal(true);
			return false;
		}

		let inputData = {
			dealerID: dataItem.dealerId,
			name: dropHintInputData.name,
			mail: dropHintInputData.email,
			recName: dropHintInputData.recipientName,
			recEmail: dropHintInputData.recipientEmail,
			giftReason: dropHintInputData.giftReason,
			reason: dropHintInputData.personalMessage,
			date: dropHintInputData.giftDeadline,
			loadFromMasterlink: true,
			strDiamondLinkURL: "",
			sid: "",
			did: dataItem.sku,
			shape: dataItem.shape,
			ctw: "",
			step: "",
			themeName: "",
			viewItemURL: window.location.href.toString(),
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.SaveDropHint(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							"Your request has been sent to GemFind."
						);
						setShowDropModal(false);
						setButtonLoading(false);
						setDropHintInputData(initialDropAHintData);
					} else {
						NotificationManager.error(
							"Your request has not been sent to GemFind."
						);
						setShowDropModal(false);
						setButtonLoading(false);
						setDropHintInputData(initialDropAHintData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					NotificationManager.error("Could Not Add Record");
					setShowDropModal(false);
					setButtonLoading(false);
					setDropHintInputData(initialDropAHintData);
					handleScrollToTop();
					console.log(error);
				});
		} catch (error) {
			NotificationManager.error("Sorry we had an error");
			setShowDropModal(false);
			setButtonLoading(false);
			setDropHintInputData(initialDropAHintData);
			handleScrollToTop();
			console.log(error);
		}
	};

	const showModal1 = () => {
		handleScrollToTop();
		setRequestMoreModal(true);
	};
	const closeDiamondViewModal = () => {
		setIsDiamondView(false);
		setDiamondViewInputData(initialDiamondInputData);
		setDiamondViewInputDataValidation(initialDiamondInputDataVal);
	};

	const closeDiamondViewModal1 = () => {
		setIsDiamondView1(false);
		setDiamondViewInputData1(initialDiamondInputData1);
		setDiamondViewInputDataValidation1(initialDiamondInputDataVal1);
	};

	const closeRequestMoreModal = () => {
		setRequestMoreModal(false);
		setRequestMoreInfoInputData(initialRequestMoreInfoData);
		setRequestMoreInfoInputDataValidation(initialRequestMoreInfoDataVal);
	};

	const showModal2 = () => {
		handleScrollToTop();
		setShowDropModal(true);
	};
	const showDiamondView = () => {
		handleScrollToTop();
		setIsDiamondView(true);
	};
	const showDiamondView1 = () => {
		setIsDiamondView1(true);
	};
	const hideDropHintModal = () => {
		setShowDropModal(false);
		setDropHintInputData(initialDropAHintData);
		setDropHintInputDataValidation(initialDropAHintDataVal);
	};

	const handleSubmitDiamondView = () => {
		handleSaveDiamondView();
	};

	const handleSubmitDiamondView1 = () => {
		handleSaveDiamondView1();
	};

	const handleSubmitRequestMoreInfo = () => {
		handleSaveRequestMoreInfo();
	};

	const handleSubmitSheduleViewing = () => {
		let isValid = handleValidationSchedule();
		if (isValid) {
			handleScrollToTop();
			handleSaveScheduleViewing();
		}
	};

	const navigate = useNavigate();

	const handleChooseSetting = (details) => {
		let obj = details;

		navigate(
			"/chooseYourSetting" +
				`?isSocialPage=True&DealerID=${paramdealerId}&dealerID1=${paramdealerID1}&dealerID=${obj.dealerID1}&paramDetailLinkURL=${paramDetailLinkURL}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&size=${obj.size1}&pairId=${obj.pairID}&totalPrice=${obj.totalPrice}&diamondId=${diamondId}&isTab=false&tabName=chooseyourmounting&diamondViewEdit=True`
		);

		dispatch(handleViewAddToStudPageDetails(obj));
		dispatch(handleEditViewShow());
		dispatch(handleActiveTab("chooseYourSetting"));
	};

	const showModal3 = () => {
		handleScrollToTop();
		setEmailFriendModal(true);
	};
	const closeEmailFriendModal = () => {
		setEmailFriendModal(false);
		setEmailFriendInputData(initialEmailAFriendInputData);
		setEmailFriendInputDataValidation(initialEmailAFriendInputDataVal);
	};

	const handleSubmitEmailFriend = () => {
		handleSaveEmailFriend();
	};

	const showModal5 = () => {
		handleScrollToTop();
		setOpenScheduleViewing(true);
	};
	const closeScheduleViewingModal = () => {
		setOpenScheduleViewing(false);
		setScheduleViewingInputData(initialScheduleViewingData);
		setScheduleViewingInputDataValidation({
			nameval: "",
		});
	};

	function handlePrintDiamondList() {
		window.print();
	}

	const handleCancel6 = () => {
		setIsModalVisible6(false);
	};

	const GetChooseSettingItemDetails = (
		dealerId,
		shapeList,
		priceMin,
		priceMax,
		caratMin,
		caratMax,
		colorMin,
		colorMax,
		clarityMin,
		clarityMax,
		cutgradeMin,
		cutgradeMax,
		symmetryMin,
		symmetryMax,
		polishMin,
		polishMax,
		fluorescenceMin,
		fluorescenceMax,
		certificate,
		sku,
		pairCode,
		pageSize,
		currentPage,
		orderBy,
		orderType,
		linkurl,
		did,
		depthMin,
		depthMax,
		tableMin,
		tableMax,
		caratPriceMin,
		caratPriceMax,
		cutGradeList,
		colorList,
		clarityList,
		polishList,
		symmetryList,
		fluorescenceList,
		themeName,
		countryTaxRate,
		countryCode
	) => {
		let inputData = {
			dealerID: dealerId,
			shapeList: shapeList,
			priceMin: priceMin,
			priceMax: priceMax,
			caratMin: caratMin,
			caratMax: caratMax,
			colorMin: colorMin,
			colorMax: colorMax,
			clarityMin: clarityMin,
			clarityMax: clarityMax,
			cutgradeMin: cutgradeMin,
			cutgradeMax: cutgradeMax,
			symmetryMin: symmetryMin,
			symmetryMax: symmetryMax,
			polishMin: polishMin,
			polishMax: polishMax,
			fluorescenceMin: fluorescenceMin,
			fluorescenceMax: fluorescenceMax,
			certificate: certificate,
			skuNo: sku,
			pairCode: pairCode,
			pageSize: pageSize,
			currentPage: currentPage,
			orderBy: orderBy,
			orderType: orderType,
			detailsLink: linkurl,
			did: did,
			depthMin: depthMin,
			depthMax: depthMax,
			tableMin: tableMin,
			tableMax: tableMax,
			caratPriceMin: caratPriceMin,
			caratPriceMax: caratPriceMax,
			cutGradeList: cutGradeList,
			colorList: colorList,
			clarityList: clarityList,
			polishList: polishList,
			symmetryList: symmetryList,
			fluorescenceList: fluorescenceList,
			themeName: themeName,
			countryTaxRate: countryTaxRate,
			countryCode: countryCode,
		};
		setDataLoding(true);
		try {
			ChooseSettingServices.LoadViewDiamonds(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						if (responseData.length > 0) {
							setCurrencySymbol(responseData[0].currencySign);
							setProductSettingItemList(responseData[0]);
							getIpData(responseData[0], dealerId);
							setDataItem((prevState) => ({
								...prevState,
								diamondshape: responseData[0].cut1,
								diamonddealer: responseData[0].dealerID1,
								diamondinventory: responseData[0].dealerInventoryNo1,
								diamondinventory1: responseData[0].dealerInventoryNo2,
							}));
							setDataLoding(false);
						}
						setDataLoding(false);
					} else {
						setProductSettingItemList([]);
						setProductSettingTableItemList([]);
						setDataLoding(false);
					}
				})
				.catch((error) => {
					console.log(error);
					SetCount(0);
					setDataLoding(false);
				});
		} catch (error) {
			console.log(error);
			SetCount(0);
			setDataLoding(false);
		}
	};
	const getIpData = async (responseData, id) => {
		const diamondData = responseData;
		const res = await axios.get("https://ipinfo.io/json");
		handleProductTracking(diamondData, res.data.IPv4, id);
	};

	const handleProductTracking = async (res, ip, id) => {
		try {
			let finalInputData = [
				{
					dateLinked: "",
					dealerID: id ? id.toString() : "",
					diamondInventoryID: res.diamondID1 ? res.diamondID1 : 0,
					completeSearchURL: window.location.href,
					diamondDealerID: res.dealerID1 ? res.dealerID1 : 0,
					hitCounter: 0,
					usersIPAddress: "",
					dealerStockNo: "",
					isFromRingBuilder: false,
					cut: res.cut1 ? res.cut1.toString() : "",
					carat: res.size1 ? res.size1.toString() : "",
					color: res.color1 ? res.color1.toString() : "",
					clarity: res.clarity1 ? res.clarity1.toString() : "",
					fltPrice: res.fltPrice1 ? res.fltPrice1.toString() : "",
					girdle: "",
					culet: res.culet ? res.culet.toString() : "",
					polish: res.polish1 ? res.polish1.toString() : "",
					symmetry: res.symmetry1 ? res.symmetry1.toString() : "",
					comments: "",
					fluorescence: res.flouresence1 ? res.flouresence1.toString() : "",
					measurements: res.measurements1 ? res.measurements1.toString() : "",
					certificate: res.certificate1 ? res.certificate1.toString() : "",
					certificateNo: res.certificateNo1
						? res.certificateNo1.toString()
						: "",
					depth: res.depth1 ? res.depth1.toString() : "",
					tableMes: res.tableMeasure1 ? res.tableMeasure1.toString() : "",
					cutGrade: res.cutGrade1 ? res.cutGrade1.toString() : "",
					sellingPrice: res.fltPrice1 ? res.fltPrice1.toString() : "",
					isLabgrown: socialpageName == "Lab" ? "1" : "0",
				},
				{
					dateLinked: "",
					dealerID: id ? id.toString() : "",
					diamondInventoryID: res.diamondID2 ? res.diamondID2 : 0,
					completeSearchURL: window.location.href,
					diamondDealerID: res.dealerID2 ? res.dealerID2 : 0,
					hitCounter: 0,
					usersIPAddress: "",
					dealerStockNo: "",
					isFromRingBuilder: false,
					cut: res.cut2 ? res.cut2.toString() : "",
					carat: res.size2 ? res.size2.toString() : "",
					color: res.color2 ? res.color2.toString() : "",
					clarity: res.clarity2 ? res.clarity2.toString() : "",
					fltPrice: res.fltPrice2 ? res.fltPrice2.toString() : "",
					girdle: "",
					culet: res.culet ? res.culet.toString() : "",
					polish: res.polish2 ? res.polish2.toString() : "",
					symmetry: res.symmetry2 ? res.symmetry2.toString() : "",
					comments: "",
					fluorescence: res.flouresence2 ? res.flouresence2.toString() : "",
					measurements: res.measurements2 ? res.measurements2.toString() : "",
					certificate: res.certificate2 ? res.certificate2.toString() : "",
					certificateNo: res.certificateNo2
						? res.certificateNo2.toString()
						: "",
					depth: res.depth2 ? res.depth2.toString() : "",
					tableMes: res.tableMeasure2 ? res.tableMeasure2.toString() : "",
					cutGrade: res.cutGrade2 ? res.cutGrade2.toString() : "",
					sellingPrice: res.fltPrice2 ? res.fltPrice2.toString() : "",
					isLabgrown: socialpageName == "Lab" ? "1" : "0",
				},
			];

			await ChooseSettingServices.StudDiamondTracking(finalInputData)
				.then((response) => {})
				.catch((error) => {});
		} catch (error) {}
	};
	const GetLocationLoadDetails = (id, diamondDetails) => {
		let inputData = {
			dealerID: id,
		};
		try {
			ChooseSettingServices.GetLocationDetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						setLocationDetails(responseData.Table);
						handleSimilarDiamondList(
							loginDetails?.dealerId ? loginDetails.dealerId : paramdealerId,
							diamondDetails,
							null,
							1,
							10
						);
					} else {
						setLocationDetails([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleSaveRequestMoreInfo = async () => {
		let isValid = handleValidationReuestInfo();

		if (!isValid) {
			setRequestMoreModal(true);
			return false;
		}

		let inputData = {
			dealerID: dataItem.dealerId,
			email: requestMoreInfoInputData.email,
			phone: requestMoreInfoInputData.phoneNumber,
			byEmail:
				requestMoreInfoInputData.contactPreference == "byEmail" ? true : false,
			comments: requestMoreInfoInputData.personalMessage,
			name: requestMoreInfoInputData.name,
			did: dataItem.diamondinventory + "," + dataItem.diamondinventory1,
			retailerID: dataItem.diamonddealer.toString(),
			callforprice: myOptionsData?.showCallForPrice ?? false,
			price: myOptionsData?.showCallForPrice
				? "Call For Price"
				: productSettingItemList.fltPrice1?.toString() ?? "0",
			price1:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice1).toFixed(2).toString(),
			price2:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice2).toFixed(2).toString(),
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.SaveRequestInfo(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							"Your request has been sent to GemFind. A representative will be contacting you shortly."
						);
						setButtonLoading(false);
						setRequestMoreModal(false);
						setRequestMoreInfoInputData(initialRequestMoreInfoData);
					} else {
						NotificationManager.error(
							"Your request has not been sent to GemFind"
						);
						setButtonLoading(false);
						setRequestMoreModal(false);
						setRequestMoreInfoInputData(initialRequestMoreInfoData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					setButtonLoading(false);
					setRequestMoreModal(false);
					setRequestMoreInfoInputData(initialRequestMoreInfoData);
					console.log(error);
					handleScrollToTop();
				});
		} catch (error) {
			setButtonLoading(false);
			setRequestMoreModal(false);
			setRequestMoreInfoInputData(initialRequestMoreInfoData);
			console.log(error);
			handleScrollToTop();
		}
	};

	const handleSaveDiamondView = async () => {
		let isValid = handleValidationDiamondView();

		if (!isValid) {
			setIsDiamondView(true);
			return false;
		}
		let inputData = {
			dealerID: dataItem.dealerId,
			name: diamondViewInputData.name,
			email: diamondViewInputData.email,
			phone: diamondViewInputData.phone,
			comments: diamondViewInputData.comments,
			did: productSettingItemList.diamondID1
				? productSettingItemList.diamondID1
				: "",
			retailerID: productSettingItemList.dealerID1
				? productSettingItemList.dealerID1
				: "",
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.StudBuilderRequestCertificate(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success("Record Added Successfully");
						setButtonLoading(false);
						setIsDiamondView(false);
						setDiamondViewInputData(initialDiamondInputData);
					}
				})
				.catch((error) => {
					NotificationManager.error("Could Not Add Record");
					setButtonLoading(false);
					setIsDiamondView(false);
					console.log(error);
					setDiamondViewInputData(initialDiamondInputData);
				});
		} catch (error) {
			NotificationManager.error("Sorry we had an error");
			setButtonLoading(false);
			setIsDiamondView(false);
			console.log(error);
			setDiamondViewInputData(initialDiamondInputData);
		}
	};

	const handleSaveDiamondView1 = async () => {
		let isValid = handleValidationDiamondView1();

		if (!isValid) {
			setIsDiamondView1(true);
			return false;
		}
		let inputData = {
			dealerID: dataItem.dealerId,
			name: diamondViewInputData1.name1,
			email: diamondViewInputData1.email1,
			phone: diamondViewInputData1.phone1,
			comments: diamondViewInputData1.comments1,
			did: productSettingItemList.diamondID2
				? productSettingItemList.diamondID2
				: "",
			retailerID: productSettingItemList.dealerID2
				? productSettingItemList.dealerID2
				: "",
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.StudBuilderRequestCertificate(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success("Record Added Successfully");
						setButtonLoading(false);
						setIsDiamondView1(false);
						setDiamondViewInputData1(initialDiamondInputData1);
					}
				})
				.catch((error) => {
					NotificationManager.error("Could Not Add Record");
					setButtonLoading(false);
					setIsDiamondView1(false);
					setDiamondViewInputData1(initialDiamondInputData1);
					console.log(error);
				});
		} catch (error) {
			NotificationManager.error("Sorry we had an error");
			setButtonLoading(false);
			setIsDiamondView1(false);
			setDiamondViewInputData1(initialDiamondInputData1);
			console.log(error);
		}
	};

	const handleSaveScheduleViewing = async () => {
		const locationName = locationDetails?.find(
			(e) => e?.LocationId == scheduleViewingInputData?.location
		)?.["LocationName"];
		let inputData = {
			retailerID: dataItem.diamonddealer,
			dealerID: dataItem.dealerId,
			emailChk: scheduleViewingInputData.email,
			schedulPnone: scheduleViewingInputData.phoneNumber,
			appntMsg: scheduleViewingInputData.message,
			schedulName: scheduleViewingInputData.name,
			appntDate: scheduleViewingInputData.appointmentDate,
			hndAppntTime: scheduleViewingInputData.appointmentTime,
			did: dataItem.diamondinventory + "," + dataItem.diamondinventory1,
			shape: dataItem.diamondshape,
			ctw: "",
			strDiamondLinkURL: "",
			step: "",
			themeName: "",
			loadFromMasterlink: true,
			type: "Diamond",
			callforprice: myOptionsData?.showCallForPrice ?? false,
			price: myOptionsData?.showCallForPrice
				? "Call For Price"
				: productSettingItemList.fltPrice1?.toString() ?? "0",
			location: locationName,
			price1:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice1).toFixed(2).toString(),
			price2:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice2).toFixed(2).toString(),
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.SaveScheduleAViewing(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							"Your request has been sent to GemFind."
						);
						setButtonLoading(false);
						setOpenScheduleViewing(false);
						setScheduleViewingInputData(initialScheduleViewingData);
						setConfirmScheduleModal(false);
					} else {
						NotificationManager.error(
							"Your request has not been sent to GemFind."
						);
						setButtonLoading(false);
						setOpenScheduleViewing(false);
						setScheduleViewingInputData(initialScheduleViewingData);
						setConfirmScheduleModal(false);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					setButtonLoading(false);
					setOpenScheduleViewing(false);
					setScheduleViewingInputData(initialScheduleViewingData);
					setConfirmScheduleModal(false);
					console.log(error);
					handleScrollToTop();
				});
		} catch (error) {
			setButtonLoading(false);
			setOpenScheduleViewing(false);
			setScheduleViewingInputData(initialScheduleViewingData);
			setConfirmScheduleModal(false);
			console.log(error);
			handleScrollToTop();
		}
	};

	const handleSaveEmailFriend = async () => {
		let isValid = handleValidationEmailFriend();

		if (!isValid) {
			setEmailFriendModal(true);
			return false;
		}

		let inputData = {
			retailerID: dataItem.diamonddealer,
			dealerID: dataItem.dealerId,
			retailerName: emailFriendInputData.name,
			retailerEmail: emailFriendInputData.email,
			friendName: emailFriendInputData.friendName,
			friendMail: emailFriendInputData.friendEmail,
			did: dataItem.diamondinventory + "," + dataItem.diamondinventory1,
			message: emailFriendInputData.personalMessage,
			strDiamondLinkURL: "",
			callforprice: myOptionsData?.showCallForPrice ?? false,
			price: myOptionsData?.showCallForPrice
				? "Call For Price"
				: productSettingItemList.fltPrice1?.toString() ?? "0",
			price1:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice1).toFixed(2).toString(),
			price2:
				productSettingItemList?.currencycode +
				productSettingItemList?.currencySign +
				Number(productSettingItemList?.fltPrice2).toFixed(2).toString(),
		};
		setButtonLoading(true);

		try {
			completeYourRing
				.SaveEmailAFriend(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							"An email has been sent to your friend,Thanks for sharing!"
						);
						setButtonLoading(false);
						setEmailFriendModal(false);
						setEmailFriendInputData(initialEmailAFriendInputData);
					} else {
						NotificationManager.error(
							"An email has not been  sent to your friend,Thanks for sharing!"
						);
						setButtonLoading(false);
						setEmailFriendModal(false);
						setEmailFriendInputData(initialEmailAFriendInputData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					NotificationManager.error("Could Not Add Record");
					setButtonLoading(false);
					setEmailFriendModal(false);
					setEmailFriendInputData(initialEmailAFriendInputData);
					handleScrollToTop();
					console.log(error);
				});
		} catch (error) {
			NotificationManager.error("Sorry we had an error");
			setButtonLoading(false);
			setEmailFriendModal(false);
			setEmailFriendInputData(initialEmailAFriendInputData);
			handleScrollToTop();
			console.log(error);
		}
	};

	const handleOpenInternalModal = () => {
		handleScrollToTop();
		setInternalUseModal(true);
	};

	const handleCancelInternalModal = () => {
		setInternalUseModal(false);
		setShowDealerInfo(false);
		setInternalUseData({
			password: "",
		});
		setInternalUseDataValidation({
			passwordVal: "",
		});
	};

	const handleSubmitInternalData = () => {
		handleGetDealerInformation();
	};

	const handleGetDealerInformation = () => {
		let isvalid = handleValidation();

		if (!isvalid) {
			return false;
		}
		try {
			let inputData = {
				dealerID: dataItem.dealerId,
				diamondDealerID: productSettingItemList.dealerID1,
				password: internalUseData.password,
				diamondID: productSettingItemList.diamondID1,
			};

			setInternalUseModalLoading(true);
			ChooseSettingServices.GetDealerInformation(inputData)
				.then((response) => {
					let message = response?.data?.responseData?.passwordcheck;
					let dealerInfo =
						response?.data?.responseData?.getDealerInformation?.[0];
					let dealerInfo1 =
						response?.data?.responseData?.getDiamondInformation?.[0];
					if (message == false) {
						NotificationManager.error("Please enter valid password");
						setInternalUseModalLoading(false);
						setShowDealerInfo(false);
						setInternalUseData({
							password: "",
						});
					} else {
						setShowDealerInfo(true);
						setDealerInfo(dealerInfo);
						setDealerInfo1(dealerInfo1);
						setInternalUseModalLoading(false);
						setInternalUseData({
							password: "",
						});
					}
				})
				.catch((error) => {
					console.log(error);
					setInternalUseModalLoading(false);
					setInternalUseData({
						password: "",
					});
				});
		} catch (error) {
			console.log(error);
			setInternalUseModalLoading(false);
			setInternalUseData({
				password: "",
			});
		}
	};

	const handleGetDealerLocationData = (id, retailerId) => {
		try {
			let inputData = {
				dealerID: id.toString(),
				myRetailerID: retailerId,
			};
			ChooseSettingServices.GetDealerLocationData(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];

					if (message == "Success") {
						if (responseData) {
							setLocationData(responseData);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	// Image Set

	const handleImageSet = (data) => {
		setDisplayImage(data);
	};

	// Certificate

	const handleOpenCertificate = (link) => {
		window.open(
			link,
			"",
			"width=500,height=500, top=' + top + ', left=' + left"
		);
	};

	// Date

	const disablePastDate = () => {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();
		return yyyy + "-" + mm + "-" + dd;
	};

	const handleCompleteEarningDiamond = (details) => {
		let obj = details;

		dispatch(handleCompleteEarningTwoDiamond(obj));

		navigate(
			"/viewDiamondDetails" +
				`?isSocialPage=True&DealerID=${paramdealerId}&dealerID1=${paramdealerID1}&paramDetailLinkURL=${obj.detailLinkURL}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&diamondId=${obj.diamondID1}&isTab=false&tabName=selectYourDiamond`
		);
		setSimilarToogle(!similarToogle);
		handleScrollToTop();
	};

	const handleOpenSocialData = (url) => {
		window.open(url);
	};

	const handleSimilarDiamondList = (
		id,
		DiamondDetails,
		value,
		page,
		pageSize
	) => {
		let a = {};

		let ValColor = 0;
		let ValClarity = 0;
		let ValCut = 0;
		if (DiamondDetails.intColorPriority == "D") {
			ValColor = 68;
		} else if (DiamondDetails.intColorPriority == "E") {
			ValColor = 69;
		} else if (DiamondDetails.intColorPriority == "F") {
			ValColor = 70;
		} else if (DiamondDetails.intColorPriority == "G") {
			ValColor = 71;
		} else if (DiamondDetails.intColorPriority == "H") {
			ValColor = 72;
		} else if (DiamondDetails.intColorPriority == "I") {
			ValColor = 73;
		} else if (DiamondDetails.intColorPriority == "J") {
			ValColor = 74;
		} else if (DiamondDetails.intColorPriority == "K") {
			ValColor = 75;
		} else if (DiamondDetails.intColorPriority == "L") {
			ValColor = 76;
		} else if (DiamondDetails.intColorPriority == "M") {
			ValColor = 77;
		} else if (DiamondDetails.intColorPriority == "N") {
			ValColor = 78;
		} else if (DiamondDetails.intColorPriority == "O") {
			ValColor = 79;
		} else if (DiamondDetails.intColorPriority == "P") {
			ValColor = 80;
		} else {
			ValColor = 81;
		}
		let ValColorLess = ValColor - 1;
		let ValColorMore = ValColor + 1;
		let ColorList = "";
		if (ValColorLess <= 68) {
			ColorList = ValColor + "," + ValColorMore;
		} else if (ValColorMore > 77) {
			ColorList = ValColorLess + "," + ValColor;
		} else if (ValColorMore > 77 && ValColorLess <= 68) {
			ColorList = ValColor;
		} else {
			ColorList = ValColorLess + "," + ValColor + "," + ValColorMore;
		}
		if (DiamondDetails.txtClarity == "FL") {
			ValClarity = 1;
		} else if (DiamondDetails.txtClarity == "IF") {
			ValClarity = 2;
		} else if (DiamondDetails.txtClarity == "VVS1") {
			ValClarity = 3;
		} else if (DiamondDetails.txtClarity == "VVS2") {
			ValClarity = 4;
		} else if (DiamondDetails.txtClarity == "VS1") {
			ValClarity = 5;
		} else if (DiamondDetails.txtClarity == "VS2") {
			ValClarity = 6;
		} else if (DiamondDetails.txtClarity == "SI1") {
			ValClarity = 7;
		} else if (DiamondDetails.txtClarity == "SI2") {
			ValClarity = 8;
		} else if (DiamondDetails.txtClarity == "SI3") {
			ValClarity = 9;
		} else if (DiamondDetails.txtClarity == "I1") {
			ValClarity = 10;
		} else if (DiamondDetails.txtClarity == "I2") {
			ValClarity = 11;
		} else {
			ValClarity = 12;
		}
		let ClarityList = "";
		let ValClarityLess = ValClarity - 1;
		let ValClarityMore = ValClarity + 1;

		if (ValClarityLess <= 1) {
			ClarityList = ValClarity + "," + ValClarityMore;
		} else if (ValClarityMore > 10) {
			ClarityList = ValClarityLess + "," + ValClarity;
		} else if (ValClarityMore > 1 && ValClarityLess <= 10) {
			ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
		} else {
			ClarityList = ValClarityMore + "," + ValClarity + "," + ValClarityLess;
		}
		if (DiamondDetails.txtCutlet.toLowerCase() == "ideal") {
			ValCut = 1;
		} else if (DiamondDetails.txtCutlet.toLowerCase() == "excellent") {
			ValCut = 2;
		} else if (DiamondDetails.txtCutlet.toLowerCase() == "very good") {
			ValCut = 3;
		} else if (DiamondDetails.txtCutlet.toLowerCase() == "good") {
			ValCut = 4;
		} else if (DiamondDetails.txtCutlet.toLowerCase() == "fair") {
			ValCut = 5;
		} else {
			ValCut = 6;
		}

		let CutList = "";
		let ValCutLess = ValCut - 1;
		let ValCutMore = ValCut + 1;

		if (ValCutLess <= 1) {
			CutList = ValCut + "," + ValCutMore;
		} else if (ValCutMore > 7) {
			CutList = "";
		} else if (ValCutMore > 1 && ValCutLess <= 7) {
			ClarityList = ValCut;
		} else {
			ClarityList = ValCutMore + "," + ValCut + "," + ValCutLess;
		}

		try {
			let inputData;
			inputData = {
				dealerID: id.toString(),
				priceMin: "0",
				priceMax: "9999999",
				caratMin: "0",
				caratMax: "22",
				colorMin: "68",
				colorMax: "80",
				clarityMin: "1", //FROM INTIAL FILTER
				clarityMax: "11", //FROM INTIAL FILTER
				cutGradeMin: "1",
				cutGradeMax: "5",
				symmetryMin: "1",
				symmetryMax: "4",
				polishMin: "1",
				polishMax: "4",
				fluorescenceMin: "1",
				fluorescenceMax: "5",
				certificate: "",
				skuNo: value ? value : "",
				pairCode: "",
				pageSize: pageSize?.toString(),
				currentPage: page?.toString(),
				orderBy: "TotalPrice",
				orderType: "asc",
				detailsLink: "StudDiamond_Detail.aspx",
				did: "",
				depthMin: "",
				depthMax: "100",
				tableMin: "",
				tableMax: "100",
				caratPriceMin: "",
				caratPriceMax: "",
				themeName: "WhiteTheme",
				polishList: "",
				symmetryList: "",
				fluorescenceList: "",
				colorList: ValColor.toString(), // Logic from above input
				clarityList: ValClarity.toString(), //Logic from above input
				shapeList:
					DiamondDetails.shapeFilters.length > 0
						? DiamondDetails.shapeFilters.toString()
						: "",
				cutGradeList: ValCut.toString(),
				countryTaxRate: "",
				countryCode: "",
			};

			setTableLoading(true);

			SelectYourDiamondService.LoadDiamonds(inputData)

				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						if (responseData) {
							setProductSettingTableItemList(responseData);
							setTableLoading(false);
							SetCount(responseData[0].intTotalRecords);
						} else {
							setProductSettingTableItemList([]);
							setTableLoading(false);
							SetCount(0);
						}
						setTableLoading(false);
					}
					setTableLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setTableLoading(false);
				});
		} catch (error) {
			console.log(error);
			setTableLoading(false);
		}
	};

	function onChange(page, pageSize) {
		if (page !== pageNo) {
			setPageNo(page);
			handleSimilarDiamondList(
				paramdealerId,
				selectedDiamondDetails,
				searchedValue,
				page,
				pageSize
			);
		}
	}

	function onShowSizeChange(current, pageNewSize) {
		if (pageNewSize !== pageSize) {
			setPageSize(pageNewSize);
			handleSimilarDiamondList(
				paramdealerId,
				selectedDiamondDetails,
				searchedValue,
				pageNo,
				pageNewSize
			);
		}
	}

	const handleScrollToTop = () => {
		const element = document.getElementById("topHeader");
		element.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest",
		});
	};

	const truncateSku = (sku) => {
		if (sku.length > 10) {
			return sku.substring(0, 10) + "...";
		}
		return sku;
	};

	return (
		<React.Fragment>
			<span className="back__btn" onClick={handleEditDiamond}>
				{" "}
				{"<< Change Diamond"}{" "}
			</span>
			<div className="allprod_section jwlsrch__section app__preview choose__setting p-1">
				<div className="row">
					<div className="col-md-12">
						<div className="prodetail__section">
							<div className="col-md-12">
								<div className="prod__maindiv app__preview p-0 border-0">
									<div className="row">
										{dataLoding ? (
											<Spin spinning={dataLoding} />
										) : (
											<>
												<div className="col-lg-6">
													<div className="row">
														<div className="col-lg-12">
															<div className="thimage__slider thumbnail__top">
																<div className="shapes__block selected">
																	<div className="whole__box">
																		<div className="diamond__block">
																			<p>Diamond 1</p>
																			<div className="image__thbox">
																				<div className="box__one">
																					<img
																						onClick={() =>
																							handleImageSet(
																								productSettingItemList.htmlImage
																							)
																						}
																						src={
																							productSettingItemList.htmlImage
																						}
																					/>
																				</div>
																				<div className="box__one">
																					<img
																						onClick={() =>
																							handleImageSet(
																								productSettingItemList.bigImageFileName
																							)
																						}
																						src={
																							productSettingItemList.bigImageFileName
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="diamond__block">
																			<p>Diamond 2</p>
																			<div className="image__thbox">
																				<div className="box__one">
																					<img
																						onClick={() =>
																							handleImageSet(
																								productSettingItemList.htmlImage2
																							)
																						}
																						src={
																							productSettingItemList.htmlImage2
																						}
																					/>
																				</div>
																				<div className="box__one">
																					<img
																						onClick={() =>
																							handleImageSet(
																								productSettingItemList.bigImageFileName
																							)
																						}
																						src={
																							productSettingItemList.bigImageFileName
																						}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="big__img thimage__slider">
																<img
																	src={
																		displayImage
																			? displayImage
																			: productSettingItemList.htmlImage
																	}
																	className="img-fluid"
																/>
															</div>
														</div>

														<div className="col-md-12">
															<div className="diamond__detail">
																<div className="row">
																	<div className="col-md-12 mb-2">
																		<div>
																			<span>Diamond 1 Grading Report</span>
																			<span
																				onClick={() =>
																					handleOpenCertificate(
																						productSettingItemList.certificateLink
																					)
																				}
																				className="linkText ml-2">
																				View
																			</span>
																			{productSettingItemList.certificateLink ==
																				"" && (
																				<span
																					onClick={showDiamondView}
																					className="linkText ml-2">
																					Request Certificate
																				</span>
																			)}
																		</div>
																	</div>
																	<div className="col-md-2">
																		{productSettingItemList.certificate1 ==
																		"GIA" ? (
																			<img src={GIA} width="70px" />
																		) : productSettingItemList.certificate1 ==
																		  "IGI" ? (
																			<img src={IGI} width="70px" />
																		) : productSettingItemList.certificate1 ==
																		  "AGS" ? (
																			<img src={AGS} width="70px" />
																		) : (
																			<img src={AllImg} width="70px" />
																		)}
																	</div>
																	<div className="col-md-10">
																		<p>
																			This diamond is graded by{" "}
																			{productSettingItemList.certificate1
																				? productSettingItemList.certificate1
																				: ""}
																			. This provides you an authoritative
																			analysis of your diamond. It also verifies
																			that your diamond meets all the specific
																			quality requirements.
																		</p>
																	</div>
																</div>
															</div>
														</div>

														<div className="col-md-12">
															<div className="diamond__detail">
																<div className="row">
																	<div className="col-md-12 mb-2">
																		<div>
																			<span>Diamond 2 Grading Report</span>
																			<span
																				onClick={() =>
																					handleOpenCertificate(
																						productSettingItemList.certificateLink2
																					)
																				}
																				className="linkText ml-2">
																				View
																			</span>
																			{productSettingItemList.certificateLink2 ==
																				"" && (
																				<span
																					onClick={showDiamondView1}
																					className="linkText ml-2">
																					Request Certificate
																				</span>
																			)}
																		</div>
																	</div>
																	<div className="col-md-2">
																		{productSettingItemList.certificate2 ==
																		"GIA" ? (
																			<img src={GIA} width="70px" />
																		) : productSettingItemList.certificate2 ==
																		  "IGI" ? (
																			<img src={IGI} width="70px" />
																		) : productSettingItemList.certificate2 ==
																		  "AGS" ? (
																			<img src={AGS} width="70px" />
																		) : (
																			<img src={AllImg} width="70px" />
																		)}
																	</div>
																	<div className="col-md-10">
																		<p>
																			This diamond is graded by{" "}
																			{productSettingItemList.certificate2
																				? productSettingItemList.certificate2
																				: ""}
																			. This provides you an authoritative
																			analysis of your diamond. It also verifies
																			that your diamond meets all the specific
																			quality requirements.
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-6 col-md-12">
													<div className="prodesc__div">
														<Wrapper>
															<Tabs
																defaultActiveKey="home"
																id="uncontrolled-tab-example"
																className="tab__div border-0">
																<Tab eventKey="home" title="Items Details">
																	<div className="tab__contentdiv border__block p-0">
																		<div className="head__div">
																			<h4>Your Diamond Pair</h4>
																		</div>
																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="col-md-12 mt-3">
																					<p>
																						Your Diamond Pair has a Total Carat
																						Weight of{" "}
																						{productSettingItemList.size1 &&
																						productSettingItemList.size2
																							? parseFloat(
																									productSettingItemList.size1
																							  ) +
																							  parseFloat(
																									productSettingItemList.size2
																							  )
																							: 0}{" "}
																						Carat. These carefully selected
																						diamonds work well together because
																						of their near-identical cut, color,
																						clarity and size. To compare the
																						specific diamond details, see the
																						charts and information below.
																					</p>

																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								Diamond 1
																							</p>
																						</div>
																						<div className="col-md-6">
																							<div className="input__block graded__by">
																								<p className="d-flex">
																									SKU#{" "}
																									<span
																										className="sku"
																										title={
																											productSettingItemList.dealerInventoryNo1
																												? productSettingItemList.dealerInventoryNo1
																												: ""
																										}>
																										{truncateSku(
																											productSettingItemList.dealerInventoryNo1 ||
																												""
																										)}
																									</span>
																								</p>
																							</div>
																						</div>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">
																							Ct Weight
																						</p>
																						<p>
																							{productSettingItemList.size1
																								? productSettingItemList.size1
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">Color</p>
																						<p>
																							{productSettingItemList.color1
																								? productSettingItemList.color1
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12 mobile__version">
																					<hr />
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">Cut</p>
																						<p>
																							{productSettingItemList.cutGrade1
																								? productSettingItemList.cutGrade1
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">
																							Clarity
																						</p>
																						<p>
																							{productSettingItemList.clarity1
																								? productSettingItemList.clarity1
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<hr />
																				</div>
																			</div>

																			<div className="row top__content m-0">
																				<div className="col-md-12 mt-3">
																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								Diamond 2
																							</p>
																						</div>
																						<div className="col-md-6">
																							<div className="input__block graded__by">
																								<p className="d-flex">
																									SKU#{" "}
																									<span
																										className="sku"
																										title={
																											productSettingItemList.dealerInventoryNo2
																												? productSettingItemList.dealerInventoryNo2
																												: ""
																										}>
																										{truncateSku(
																											productSettingItemList.dealerInventoryNo2 ||
																												""
																										)}
																									</span>
																								</p>
																							</div>
																						</div>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">
																							Ct Weight
																						</p>
																						<p>
																							{productSettingItemList.size2
																								? productSettingItemList.size2
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">Color</p>
																						<p>
																							{productSettingItemList.color2
																								? productSettingItemList.color2
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12 mobile__version">
																					<hr />
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">Cut</p>
																						<p>
																							{productSettingItemList.cutGrade2
																								? productSettingItemList.cutGrade2
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-3 col-6">
																					<div className="detailblk">
																						<p className="font__bold">
																							Clarity
																						</p>
																						<p>
																							{productSettingItemList.clarity2
																								? productSettingItemList.clarity2
																								: ""}
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<div className="detailblk">
																						<span className="subheading">
																							{myOptionsData.showCallForPrice ==
																							true ? (
																								<>Call For Price</>
																							) : (
																								<>
																									{productSettingItemList.totalPrice ==
																									"0" ? (
																										" Call For Price"
																									) : (
																										<span className="subheading">
																											{productSettingItemList?.currencycode ??
																												""}{" "}
																											{currencySymbol}
																											{parseInt(
																												productSettingItemList.totalPrice
																											)
																												.toFixed()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													","
																												)}
																										</span>
																									)}
																								</>
																							)}
																						</span>
																						{myOptionsData.showOnlyDiamondSearch ==
																						false ? (
																							<Button
																								className="primary-btn mx-5"
																								onClick={() =>
																									handleChooseSetting(
																										productSettingItemList
																									)
																								}>
																								Add To Your Stud
																							</Button>
																						) : null}
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="desc__maindiv mt-3">
																			<div className="row top__content m-0">
																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p onClick={showModal2}>
																							<span className="img__box">
																								<img src={drophint} />
																							</span>
																							<span>Drop A Hint</span>
																						</p>
																					</div>
																					<div className="col-sm-12 col-md-6">
																						<p onClick={showModal1}>
																							<span className="img__box">
																								<img src={infoblack} />
																							</span>
																							<span>Request More Info</span>
																						</p>
																					</div>
																				</div>

																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p onClick={showModal3}>
																							<span className="img__box">
																								<img src={emailicon} />{" "}
																							</span>
																							<span>E-Mail A Friend</span>
																						</p>
																					</div>

																					{myOptionsData.showScheduleViewing ==
																						true && (
																						<div className="col-sm-12 col-md-6">
																							<p onClick={showModal5}>
																								<span className="img__box">
																									<img src={calender} />
																								</span>
																								<span>Schedule Viewing</span>
																							</p>
																						</div>
																					)}
																				</div>

																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p
																							onClick={() => {
																								handlePrintDiamondList();
																							}}>
																							<span className="img__box">
																								<img src={IconPrint} />
																							</span>
																							<span>Print Details</span>
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<p>
																						Have a question regarding this item?
																						Our specialists are available to
																						assist you.
																					</p>
																				</div>
																				{myOptionsData.showAddress == true && (
																					<div className="col-md-12">
																						<p className="font__bold d-flex justify-between mb-0">
																							<span className="font__bold">
																								{locationData.locationName &&
																									parse(
																										locationData.locationName
																									)}
																							</span>
																						</p>
																						<p className="font__bold mb-0">
																							<span className="font__bold">
																								{locationData &&
																									locationData.phone}
																							</span>
																						</p>
																						<p className="font__bold">
																							<a
																								href={`mailto:${
																									locationData &&
																									locationData.emailID
																								}`}>
																								{locationData &&
																									locationData.emailID}
																							</a>
																						</p>
																					</div>
																				)}

																				<div className="col-md-12">
																					<div className="dtl__socialsection">
																						{myOptionsData.showFacebookShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										productSettingItemList.faceBookLink
																											? productSettingItemList.faceBookLink
																											: ""
																									)
																								}
																								className="dtl__socialbtn fb__btn">
																								<img src={DtlFacebook} />
																							</button>
																						)}

																						{myOptionsData.showPinterestShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										productSettingItemList.pinterestLink
																											? productSettingItemList.pinterestLink
																											: ""
																									)
																								}
																								className="dtl__socialbtn pintrest">
																								<img src={DtlPintrest} />
																							</button>
																						)}
																						{myOptionsData.showTwitterShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										productSettingItemList.twitterLink
																											? productSettingItemList.twitterLink
																											: ""
																									)
																								}
																								className="dtl__socialbtn tweeter">
																								<img src={DtlTwitter} />
																							</button>
																						)}
																						{/* {myOptionsData.ShowInstagramPlus ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleOpenSocialData(
                                                    productSettingItemList.instaLink
                                                      ? productSettingItemList.instaLink
                                                      : ""
                                                  )
                                                }
                                                className="dtl__socialbtn instagram"
                                              >
                                                <img src={DtlInstagram} />
                                              </button>
                                            )} */}

																						{/* {myOptionsData.showFacebookLike ==
                                              true && (
                                                <button
                                                  onClick={() =>
                                                    handleOpenSocialData(
                                                      productSettingItemList.faceBookLike
                                                        ? productSettingItemList.faceBookLike
                                                        : ""
                                                    )
                                                  }
                                                  className="dtl__socialbtn googlePlus"
                                                >
                                                  <img
                                                    src={FaceBookLike}
                                                    className="fbLike"
                                                  />
                                                </button>
                                              )} */}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</Tab>

																<Tab eventKey="profile" title="Specifications">
																	<div className="tab__contentdiv border__block p-0">
																		<div className="desc__maindiv multiple__diamond">
																			<div
																				className="desc__div head__div"
																				style={{ backgroundColor: "#A1A1A1" }}>
																				<span className="head__txt">
																					Details
																				</span>
																				<span className="head__txt">
																					Diamond 1
																				</span>
																				<span className="head__txt">
																					Diamond 2
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Stock Number
																				</span>
																				<span
																					className="desc__txt"
																					title={
																						productSettingItemList.dealerInventoryNo1 ||
																						""
																					}>
																					{truncateSku(
																						productSettingItemList.dealerInventoryNo1 ||
																							""
																					)}
																				</span>
																				<span
																					className="desc__txt"
																					title={
																						productSettingItemList.dealerInventoryNo2 ||
																						""
																					}>
																					{truncateSku(
																						productSettingItemList.dealerInventoryNo2 ||
																							""
																					)}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">Price</span>
																				<span className="desc__txt">
																					{myOptionsData.showCallForPrice ==
																					true ? (
																						"Call For Price"
																					) : (
																						<>
																							{productSettingItemList.fltPrice1
																								? productSettingItemList.fltPrice1 ==
																								  0
																									? "Call For Price"
																									: `${
																											productSettingItemList?.currencycode +
																												" " ?? ""
																									  }${
																											productSettingItemList?.currencySign ??
																											" "
																									  }${Number(
																											productSettingItemList.fltPrice1
																									  ).toFixed(2)}`
																								: "Call For Price"}
																						</>
																					)}
																				</span>
																				<span className="desc__txt">
																					{myOptionsData.showCallForPrice ==
																					true ? (
																						"Call For Price"
																					) : (
																						<>
																							{productSettingItemList.fltPrice2
																								? productSettingItemList.fltPrice2 ==
																								  0
																									? "Call For Price"
																									: `${
																											productSettingItemList?.currencycode +
																												" " ?? ""
																									  }${
																											productSettingItemList?.currencySign ??
																											" "
																									  }${Number(
																											productSettingItemList.fltPrice2
																									  ).toFixed(2)}`
																								: "Call For Price"}
																						</>
																					)}
																				</span>
																			</div>
																			{productSettingItemList.costperCarat1 !==
																				"0" &&
																			productSettingItemList.costperCarat2 !==
																				"0" ? (
																				<div className="desc__div">
																					<span className="head__txt">
																						Price Per Carat
																					</span>
																					<span className="desc__txt">
																						{myOptionsData.showCallForPrice ==
																						true ? (
																							"Call For Price"
																						) : (
																							<>
																								{productSettingItemList.costperCarat1
																									? `${
																											productSettingItemList?.currencycode +
																												" " ?? ""
																									  }${
																											productSettingItemList.currencySign
																									  }${
																											productSettingItemList.costperCarat1
																									  }`
																									: ""}
																							</>
																						)}
																					</span>
																					<span className="desc__txt">
																						{myOptionsData.showCallForPrice ==
																						true ? (
																							"Call For Price"
																						) : (
																							<>
																								{productSettingItemList.costperCarat2
																									? `${
																											productSettingItemList?.currencycode +
																												" " ?? ""
																									  }${
																											productSettingItemList.currencySign
																									  }${
																											productSettingItemList.costperCarat2
																									  }`
																									: ""}
																							</>
																						)}
																					</span>
																				</div>
																			) : null}

																			<div className="desc__div">
																				<span className="head__txt">
																					Carat Weight
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.size1
																						? productSettingItemList.size1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.size2
																						? productSettingItemList.size2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">Cut</span>
																				<span className="desc__txt">
																					{productSettingItemList.cutGrade1
																						? productSettingItemList.cutGrade1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.cutGrade2
																						? productSettingItemList.cutGrade2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">Color</span>
																				<span className="desc__txt">
																					{productSettingItemList.color1
																						? productSettingItemList.color1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.color2
																						? productSettingItemList.color2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Clarity
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.clarity1
																						? productSettingItemList.clarity1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.clarity2
																						? productSettingItemList.clarity2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Depth %
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.depth1
																						? productSettingItemList.depth1
																						: 0}
																					%
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.depth2
																						? productSettingItemList.depth2
																						: 0}
																					%
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Table %
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.tableMeasure1
																						? productSettingItemList.tableMeasure1
																						: 0}
																					%
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.tableMeasure2
																						? productSettingItemList.tableMeasure2
																						: 0}
																					%
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Symmetry
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.symmetry1
																						? productSettingItemList.symmetry1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.symmetry2
																						? productSettingItemList.symmetry2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Fluorescence
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.flouresence1
																						? productSettingItemList.flouresence1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.flouresence2
																						? productSettingItemList.flouresence2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Measurement
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.measurements1
																						? productSettingItemList.measurements1
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.measurements2
																						? productSettingItemList.measurements2
																						: ""}
																				</span>
																			</div>
																			<div className="desc__div">
																				<span className="head__txt">
																					Origin
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.depth2
																						? productSettingItemList.depth2
																						: ""}
																				</span>
																				<span className="desc__txt">
																					{productSettingItemList.depth2
																						? productSettingItemList.depth2
																						: ""}
																				</span>
																			</div>
																		</div>
																	</div>
																</Tab>
															</Tabs>
														</Wrapper>
													</div>
												</div>
												{myOptionsData.internalUseLink == true && (
													<span
														className="font__bold mr-2 mt-3 inline-block linkText w-auto"
														onClick={handleOpenInternalModal}>
														For Internal Use Only
													</span>
												)}
												<div className="col-md-12 mt-2">
													<div className="filter_section p-0">
														<div className="col-md-12">
															<div className="filt_optndiv">
																<div className="gview__div">
																	<span className="font__bold mr-2 similar__text">
																		{count ? count : 0} SIMILAR DIAMOND PAIRS
																	</span>
																</div>
																<Search
																	placeholder="Search Diamond Stock #"
																	onSearch={(e) => handleSearchCollection(e)}
																	allowClear
																	style={{ width: 250 }}
																/>
															</div>
														</div>
													</div>
												</div>
												<Wrapper>
													<Spin spinning={loadingTable}>
														<div className="col-md-12 mt-4">
															<div className="lviewprod__tbl">
																<Spin spinning={tableLoading}>
																	<div className="table__scroll">
																		<Table
																			columns={columns}
																			className="info__tooltip__table"
																			dataSource={productSettingTableItemList}
																			scroll={{ x: 600, y: 500 }}
																			pagination={false}
																		/>
																	</div>
																	<div className="table__bottom__btn single__item">
																		<div>
																			<Pagination
																				current={pageNo}
																				pageSize={pageSize}
																				total={count}
																				onChange={onChange}
																				onShowSizeChange={onShowSizeChange}
																				showSizeChanger="true"
																			/>
																		</div>
																	</div>
																</Spin>
															</div>
															<div className="custom__table print__table">
																<table class="table table-striped">
																	<thead>
																		<tr>
																			<th scope="col">Shape</th>
																			<th scope="col">Carats</th>
																			<th scope="col">Color</th>
																			<th scope="col">Clarity</th>
																			<th scope="col">Cut</th>
																			<th scope="col">Depth</th>
																			<th scope="col">Table</th>
																			<th scope="col">Polish</th>
																			<th scope="col">Symmetry</th>
																			<th scope="col">Measurement</th>
																			<th scope="col">Certificate</th>
																			<th scope="col">Price</th>
																		</tr>
																	</thead>
																	<tbody>
																		{productSettingTableItemList.map((row) => (
																			<tr>
																				<td>
																					<div>
																						<div className="stud__img">
																							<img
																								style={{
																									width: "15px",
																									height: "15px",
																								}}
																								src={row.imageFileName}
																							/>
																							<span>{" " + row.cut1}</span>
																						</div>

																						<div className="stud__img">
																							<img
																								style={{
																									width: "15px",
																									height: "15px",
																								}}
																								src={row.imageFileName}
																							/>
																							<span>{" " + row.cut2}</span>
																						</div>
																					</div>
																				</td>
																				<td>
																					<span>{row.size1}</span>
																					<br />
																					<span>{row.size2}</span>
																				</td>
																				<td>
																					<span>{row.color1}</span>
																					<br />
																					<span>{row.color2}</span>
																				</td>
																				<td>
																					<span>{row.clarity1}</span>
																					<br />
																					<span>{row.clarity2}</span>
																				</td>
																				<td>
																					<span>{row.cutGrade1}</span>
																					<br />
																					<span>{row.cutGrade1}</span>
																				</td>
																				<td>
																					<span>{row.depth1}</span>
																					<br />
																					<span>{row.depth2}</span>
																				</td>
																				<td>
																					<span>{row.tableMeasure1}</span>
																					<br />
																					<span>{row.tableMeasure2}</span>
																				</td>
																				<td>
																					<span>{row.polish1}</span>
																					<br />
																					<span>{row.polish2}</span>
																				</td>
																				<td>
																					<span>{row.symmetry1}</span>
																					<br />
																					<span>{row.symmetry2}</span>
																				</td>

																				<td>
																					<span>{row.measurements1}</span>
																					<br />
																					<span>{row.measurements2}</span>
																				</td>
																				<td>
																					<span>{row.certificate1}</span>
																					<br />
																					<span>{row.certificate2}</span>
																				</td>
																				<td>
																					{myOptionsData.showCallForPrice ==
																					true ? (
																						<span>Call For Price</span>
																					) : (
																						<span>
																							{row.totalPrice == "0"
																								? "Call For Price"
																								: `${row?.currencycode ?? ""}${
																										row?.currencySign ?? "$"
																								  }` + row.totalPrice}
																						</span>
																					)}
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</Spin>
												</Wrapper>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Wrapper>
				<React.Fragment>
					<div id="printsection" style={{ padding: "20px", display: "none" }}>
						<span className="back__btn" onClick={handleEditDiamond}>
							{" "}
							{"<< Change Diamond"}{" "}
						</span>
						<div className="allprod_section jwlsrch__section app__preview choose__setting">
							<Spin spinning={dataLoding}>
								<div className="row">
									<div className="col-md-12">
										<div className="prodetail__section">
											<div className="col-md-12">
												<div className="prod__maindiv app__preview p-0 border-0">
													<div className="row">
														<div className="col-lg-6">
															<div className="row">
																<div className="col-lg-12">
																	<div className="thimage__slider thumbnail__top">
																		<ImageGallery
																			items={images}
																			showFullscreenButton={false}
																			renderLeftNav={renderLeftNav}
																			renderRightNav={renderRightNav}
																		/>
																		<span
																			className="font__bold mr-2 mt-3 linkText inline-block w-auto"
																			onClick={handleOpenInternalModal}>
																			For Internal Use Only
																		</span>
																		<div className="share__block">
																			<div className="share__button">
																				<img src={shareBlue} />
																			</div>
																			<div className="social__button">
																				{myOptionsData.showFacebookShare ==
																					true && <img src={shareFacebook} />}
																				{myOptionsData.showPinterestShare ==
																					true && <img src={sharePintrest} />}
																				{myOptionsData.showTwitterShare ==
																					true && <img src={shareTwitter} />}
																			</div>
																		</div>
																	</div>
																</div>

																<div className="col-md-12">
																	<div className="diamond__detail">
																		<div className="row"></div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-lg-6 col-md-12">
															<div className="prodesc__div">
																<Tabs
																	defaultActiveKey="home"
																	id="uncontrolled-tab-example"
																	className="tab__div border-0">
																	<Tab eventKey="home" title="Items Details">
																		<div className="tab__contentdiv border__block p-0">
																			<div className="head__div">
																				<h4>Your Diamond Pair</h4>
																			</div>
																			<div className="desc__maindiv">
																				<div className="row top__content m-0">
																					<div className="col-md-12 mt-3">
																						<p>
																							Your Diamond Pair has a Total
																							Carat Weight of 0.52 Carat. These
																							carefully selected diamonds work
																							well together because of their
																							near-identical cut, color, clarity
																							and size. To compare the specific
																							diamond details, see the charts
																							and information below.
																						</p>

																						<div className="row">
																							<div className="col-md-3">
																								<p className="font__bold">
																									Diamond 1
																								</p>
																							</div>
																							<div className="col-md-6">
																								<div className="input__block graded__by">
																									<p className="d-flex">
																										SKU#{" "}
																										<span className="linkText ml-1">
																											{productSettingItemList.diamondID1
																												? productSettingItemList.diamondID1
																												: ""}
																										</span>
																										<Tooltip
																											placement="right"
																											title={
																												<div className="row">
																													<div className="col-md-2">
																														<img
																															src={IGI}
																															width="60px"
																														/>
																													</div>
																													<div className="col-md-10">
																														<p>
																															This diamond is
																															graded by AGS .
																															This provides you
																															an authoritative
																															analysis of your
																															diamond.it also
																															verifies that your
																															diamond meets all
																															the specific
																															quality
																															requirements.
																														</p>
																													</div>
																												</div>
																											}>
																											<img
																												src={UnionIcon}
																												alt=""
																												className="ml-1"
																											/>
																										</Tooltip>
																									</p>
																								</div>
																							</div>
																							<div className="col-md-3 text-right">
																								<p>
																									Grading Report{" "}
																									<span
																										className="ml-2 linkText"
																										onClick={showDiamondView}>
																										View
																									</span>
																								</p>
																							</div>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Ct Weight
																							</p>
																							<p>
																								{productSettingItemList.size1
																									? productSettingItemList.size1
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Color
																							</p>
																							<p>
																								{productSettingItemList.color1
																									? productSettingItemList.color1
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">Cut</p>
																							<p>
																								{productSettingItemList.cut1
																									? productSettingItemList.cut1
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Clarity
																							</p>
																							<p>
																								{productSettingItemList.clarity1
																									? productSettingItemList.clarity1
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-12">
																						<hr />
																					</div>
																				</div>

																				<div className="row top__content m-0">
																					<div className="col-md-12 mt-3">
																						<div className="row">
																							<div className="col-md-3">
																								<p className="font__bold">
																									Diamond 2
																								</p>
																							</div>
																							<div className="col-md-6">
																								<div className="input__block graded__by">
																									<p className="d-flex">
																										SKU#{" "}
																										<span className="linkText ml-1">
																											{productSettingItemList.diamondID2
																												? productSettingItemList.diamondID2
																												: ""}
																										</span>
																										<Tooltip
																											placement="right"
																											title={
																												<div className="row">
																													<div className="col-md-2">
																														<img
																															src={IGI}
																															width="60px"
																														/>
																													</div>
																													<div className="col-md-10">
																														<p>
																															This diamond is
																															graded by AGS .
																															This provides you
																															an authoritative
																															analysis of your
																															diamond.it also
																															verifies that your
																															diamond meets all
																															the specific
																															quality
																															requirements.
																														</p>
																													</div>
																												</div>
																											}>
																											<img
																												src={UnionIcon}
																												alt=""
																												className="ml-1"
																											/>
																										</Tooltip>
																									</p>
																								</div>
																							</div>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Ct Weight
																							</p>
																							<p>
																								{productSettingItemList.size2
																									? productSettingItemList.size2
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Color
																							</p>
																							<p>
																								{productSettingItemList.color2
																									? productSettingItemList.color2
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">Cut</p>
																							<p>
																								{productSettingItemList.cut2
																									? productSettingItemList.cut2
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-3">
																						<div className="detailblk">
																							<p className="font__bold">
																								Clarity
																							</p>
																							<p>
																								{productSettingItemList.clarity2
																									? productSettingItemList.clarity2
																									: ""}
																							</p>
																						</div>
																					</div>

																					<div className="col-md-12">
																						<div className="detailblk">
																							<span className="subheading">
																								{myOptionsData.showCallForPrice ==
																								true ? (
																									<>Call For Price</>
																								) : (
																									<>
																										{productSettingItemList.totalPrice
																											? `${
																													productSettingItemList?.currencycode ??
																													""
																											  }
                                                      ${
																												productSettingItemList?.currencySign ??
																												"$"
																											}
                                                      ${
																												productSettingItemList.totalPrice
																											}`
																											: 0}
																									</>
																								)}
																							</span>
																							<button className="primary-btn mx-5">
																								Add To Your Stud
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="desc__maindiv">
																				<div className="row top__content m-0">
																					<div className="row itemdetails__btns p-0">
																						<div className="col-sm-12 col-md-6">
																							<p onClick={showModal2}>
																								<span className="img__box">
																									<img src={drophint} />
																								</span>
																								<span>Drop A Hint</span>
																							</p>
																						</div>
																						<div className="col-sm-12 col-md-6">
																							<p onClick={showModal1}>
																								<span className="img__box">
																									<img src={infoblack} />
																								</span>
																								<span>Request More Info</span>
																							</p>
																						</div>
																					</div>

																					<div className="row itemdetails__btns p-0">
																						<div className="col-sm-12 col-md-6">
																							<p onClick={showModal3}>
																								<span className="img__box">
																									<img src={emailicon} />{" "}
																								</span>
																								<span>E-Mail A Friend</span>
																							</p>
																						</div>

																						{myOptionsData.showScheduleViewing ==
																							true && (
																							<div className="col-sm-12 col-md-6">
																								<p onClick={showModal5}>
																									<span className="img__box">
																										<img src={calender} />
																									</span>
																									<span>Schedule Viewing</span>
																								</p>
																							</div>
																						)}
																					</div>

																					<div className="row itemdetails__btns p-0">
																						<div className="col-sm-12 col-md-6">
																							<p
																								onClick={() => {
																									handlePrintDiamondList();
																								}}>
																								<span className="img__box">
																									<img src={IconPrint} />
																								</span>
																								<span>Print Details</span>
																							</p>
																						</div>
																					</div>

																					<div className="col-md-12">
																						<p>
																							Have a question regarding this
																							item? Our specialists are
																							available to assist you.
																						</p>
																					</div>

																					<div className="col-md-12">
																						<p className="font__bold d-flex justify-between">
																							<span className="subheading">
																								{locationData &&
																									locationData.locationName}
																							</span>
																						</p>
																						<p className="font__bold">
																							<span className="subheading">
																								{locationData &&
																									locationData.phone}
																							</span>
																						</p>
																						<p className="font__bold">
																							{locationData &&
																								locationData.emailID}
																						</p>
																					</div>

																					<div className="col-md-12">
																						<div className="dtl__socialsection">
																							<button className="dtl__socialbtn fb__btn">
																								<img src={DtlFacebook} />
																							</button>

																							<button className="dtl__socialbtn pintrest">
																								<img src={DtlPintrest} />
																							</button>

																							<button className="dtl__socialbtn tweeter">
																								<img src={DtlTwitter} />
																							</button>

																							{/* <button className="dtl__socialbtn instagram">
                                                <img src={DtlInstagram} />
                                              </button> */}

																							{/* <button className="dtl__socialbtn">
                                                <img
                                                  className="fbLike"
                                                  src={FaceBookLike}
                                                />
                                              </button> */}
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Tab>
																	<Tab
																		eventKey="profile"
																		title="Specifications">
																		<div className="tab__contentdiv border__block p-0">
																			<div className="desc__maindiv multiple__diamond">
																				<div
																					className="desc__div head__div"
																					style={{
																						backgroundColor: "#A1A1A1",
																					}}>
																					<span className="head__txt">
																						Details
																					</span>
																					<span className="head__txt">
																						Diamond 1
																					</span>
																					<span className="head__txt">
																						Diamond 2
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Stock Number
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.diamondID1
																							? productSettingItemList.diamondID1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.diamondID2
																							? productSettingItemList.diamondID2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Price
																					</span>
																					<span className="desc__txt">
																						{myOptionsData.showCallForPrice ==
																						true ? (
																							"Call For Price"
																						) : (
																							<>
																								{productSettingItemList.fltPrice1
																									? `${
																											productSettingItemList?.currencycode ??
																											""
																									  }
                                                  ${
																										productSettingItemList?.currencySign ??
																										"$"
																									}
                                                  ${
																										productSettingItemList.fltPrice1
																									}`
																									: ""}
																							</>
																						)}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.fltPrice2
																							? productSettingItemList.fltPrice2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Price Per Carat
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.costperCarat1
																							? productSettingItemList.costperCarat1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.costperCarat2
																							? productSettingItemList.costperCarat2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Carat Weight
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.size1
																							? productSettingItemList.size1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.size2
																							? productSettingItemList.size2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">Cut</span>
																					<span className="desc__txt">
																						{productSettingItemList.cut1
																							? productSettingItemList.cut1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.cut2
																							? productSettingItemList.cut2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Color
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.color1
																							? productSettingItemList.color1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.color2
																							? productSettingItemList.color2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Clarity
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.clarity1
																							? productSettingItemList.clarity1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.clarity2
																							? productSettingItemList.clarity2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Depth %
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.depth1
																							? productSettingItemList.depth1
																							: 0}
																						%
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.depth2
																							? productSettingItemList.depth2
																							: 0}
																						%
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Table %
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.tableMeasure1
																							? productSettingItemList.tableMeasure1
																							: 0}
																						%
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.tableMeasure2
																							? productSettingItemList.tableMeasure2
																							: 0}
																						%
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Symmetry
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.symmetry1
																							? productSettingItemList.symmetry1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.symmetry2
																							? productSettingItemList.symmetry2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Fluorescence
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.flouresence1
																							? productSettingItemList.flouresence1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.flouresence2
																							? productSettingItemList.flouresence2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Measurement
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.measurements1
																							? productSettingItemList.measurements1
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.measurements2
																							? productSettingItemList.measurements2
																							: ""}
																					</span>
																				</div>
																				<div className="desc__div">
																					<span className="head__txt">
																						Origin
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.depth2
																							? productSettingItemList.depth2
																							: ""}
																					</span>
																					<span className="desc__txt">
																						{productSettingItemList.depth2
																							? productSettingItemList.depth2
																							: ""}
																					</span>
																				</div>
																			</div>
																		</div>
																	</Tab>
																</Tabs>
															</div>
														</div>

														<div className="col-md-12 mt-3">
															<div className="filter_section p-0">
																<div className="col-md-12 mt-4">
																	<div className="filt_optndiv">
																		<div className="gview__div">
																			<span className="font__bold mr-2 similar__text">
																				{count ? count : 0} SIMILAR DIAMOND
																				PAIRS
																			</span>
																		</div>
																		<Search
																			placeholder="Search Diamond Stock #"
																			onSearch={(e) =>
																				handleSearchCollection(e)
																			}
																			allowClear
																			style={{ width: 250 }}
																		/>
																	</div>
																</div>
															</div>
														</div>
														<Spin spinning={loadingTable}>
															<div className="col-md-12 mt-3">
																<div className="lviewprod__tbl">
																	<div className="table__scroll">
																		<Table
																			columns={columns}
																			dataSource={productSettingTableItemList}
																			scroll={{ x: 600, y: 500 }}
																		/>
																	</div>
																</div>
															</div>
														</Spin>
														<div className="d-flex justify-content-between mt-3 align-items-center">
															<p className="mb-0">
																<span>1 - 1</span> of 1 Diamond Paird
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Spin>
						</div>
					</div>
				</React.Fragment>
			</Wrapper>

			{/* Modal Section Starts */}

			{/* Modal View For Diamond */}

			<Modal
				title="Request Diamond Certificate:"
				visible={isDiamondView}
				style={{ top: 20 }}
				width={700}
				className="request_info"
				maskClosable={false}
				onCancel={closeDiamondViewModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeDiamondViewModal}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitDiamondView}>
						Submit
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Name <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData.name}
											className={
												diamondViewInputDataValidation.nameval && "border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange(e, "name", "input")
											}
										/>
										{diamondViewInputDataValidation.nameval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Email <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData.email}
											className={
												diamondViewInputDataValidation.emailval && "border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange(e, "email", "input")
											}
										/>
										{diamondViewInputDataValidation.emailval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Phone <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData.phone}
											className={
												diamondViewInputDataValidation.phoneval && "border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange(e, "phone", "input")
											}
										/>
										{diamondViewInputDataValidation.phoneval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation.phoneval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>Comments</label>
										<textarea
											value={diamondViewInputData.comments}
											onChange={(e) =>
												handleDiamondViewInfoChange(e, "comments", "input")
											}></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal
				title="Request Diamond Certificate:"
				visible={isDiamondView1}
				style={{ top: 20 }}
				width={700}
				className="request_info"
				maskClosable={false}
				onCancel={closeDiamondViewModal1}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeDiamondViewModal1}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitDiamondView1}>
						Submit
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Name <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData1.name1}
											className={
												diamondViewInputDataValidation1.nameval && "border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange1(e, "name1", "input")
											}
										/>
										{diamondViewInputDataValidation1.nameval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation1.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Email <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData1.email1}
											className={
												diamondViewInputDataValidation1.emailval &&
												"border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange1(e, "email1", "input")
											}
										/>
										{diamondViewInputDataValidation1.emailval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation1.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Phone <span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											value={diamondViewInputData1.phone1}
											className={
												diamondViewInputDataValidation1.phoneval &&
												"border__red"
											}
											onChange={(e) =>
												handleDiamondViewInfoChange1(e, "phone1", "input")
											}
										/>
										{diamondViewInputDataValidation1.phoneval && (
											<p className="error-color-red">
												{diamondViewInputDataValidation1.phoneval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>Comments</label>
										<textarea
											value={diamondViewInputData1.comments1}
											onChange={(e) =>
												handleDiamondViewInfoChange1(e, "comments1", "input")
											}></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			{/* Request More Info Modal Starts */}
			<Modal
				title="Request More Information"
				visible={requestMoreModal}
				style={{ top: 20 }}
				width={700}
				className="request_info"
				maskClosable={false}
				onCancel={closeRequestMoreModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeRequestMoreModal}>
						Cancel
					</Button>,
					<Button
						loading={buttonLoading}
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleSubmitRequestMoreInfo}>
						Request
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Name<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={requestMoreInfoInputData.name}
											placeholder="Enter Your Name"
											className={
												requestMoreInfoInputDataValidation.nameval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "name", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.nameval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your E-Mail Address <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={requestMoreInfoInputData.email}
											placeholder="Enter Your E-Mail Address"
											className={
												requestMoreInfoInputDataValidation.emailval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "email", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.emailval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Phone Number <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={requestMoreInfoInputData.phoneNumber}
											placeholder="Enter Your Phone Number"
											className={
												requestMoreInfoInputDataValidation.phoneNumberval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "phoneNumber", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.phoneNumberval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.phoneNumberval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>Contact Preference</label>
										<div className="row">
											<div className="radio__block col-6">
												<input
													type="radio"
													value="byEmail"
													checked={
														requestMoreInfoInputData.contactPreference ==
														"byEmail"
															? true
															: false
													}
													onChange={(e) =>
														handleRequestMoreInfoChange(
															e,
															"contactPreference",
															"radio"
														)
													}
												/>

												<label>By Email</label>
											</div>
											<div className="radio__block col-6">
												<input
													type="radio"
													value="byPhone"
													checked={
														requestMoreInfoInputData.contactPreference ==
														"byPhone"
															? true
															: false
													}
													onChange={(e) =>
														handleRequestMoreInfoChange(
															e,
															"contactPreference",
															"radio"
														)
													}
												/>

												<label>By Phone</label>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>Write A Personal Message ...</label>
										<textarea
											value={requestMoreInfoInputData.personalMessage}
											placeholder="Write A Personal Message ..."
											onChange={(e) =>
												handleRequestMoreInfoChange(
													e,
													"personalMessage",
													"radio"
												)
											}></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* Request More Info Modal Ends */}

			{/* Drop A Hint Modal Starts */}
			<Modal
				title="Drop A Hint"
				visible={showDropModal}
				style={{ top: 20 }}
				width={700}
				className="drop_a_hint"
				maskClosable={false}
				onCancel={hideDropHintModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={hideDropHintModal}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitDropHint}>
						Drop Hint
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<p>Because you deserve this.</p>
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Name<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={dropHintInputData.name}
											placeholder="Enter Your Name"
											className={
												dropHintInputDataValidation.nameval && "border__red"
											}
											onChange={(e) => handleDropHintChange(e, "name", "input")}
										/>
										{dropHintInputDataValidation.nameval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your E-Mail<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={dropHintInputData.email}
											placeholder="Enter Your E-Mail"
											className={
												dropHintInputDataValidation.emailval && "border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "email", "input")
											}
										/>
										{dropHintInputDataValidation.emailval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											Hint Recipient's Name <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={dropHintInputData.recipientName}
											placeholder="Enter Hint Recipient's Name"
											className={
												dropHintInputDataValidation.recipientNameval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "recipientName", "input")
											}
										/>
										{dropHintInputDataValidation.recipientNameval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.recipientNameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Hint Recipient's E-Mail{" "}
											<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={dropHintInputData.recipientEmail}
											placeholder="Enter Hint Recipient's E-Mail"
											className={
												dropHintInputDataValidation.recipientEmailval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "recipientEmail", "input")
											}
										/>
										{dropHintInputDataValidation.recipientEmailval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.recipientEmailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Reason For This Gift <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={dropHintInputData.giftReason}
											placeholder="Enter Reason For This Gift"
											className={
												dropHintInputDataValidation.giftReasonval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "giftReason", "input")
											}
										/>
										{dropHintInputDataValidation.giftReasonval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.giftReasonval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Gift Deadline<span className="mandatory">*</span>
										</label>
										<input
											type="date"
											value={dropHintInputData.giftDeadline}
											placeholder="Enter Gift Deadline"
											className={
												dropHintInputDataValidation.giftDeadlineval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "giftDeadline", "date")
											}
											min={disablePastDate()}
										/>
										{dropHintInputDataValidation.giftDeadlineval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.giftDeadlineval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-12">
									<div className="input__block">
										<label>
											Add A Personal Message Here ...{" "}
											<span className="mandatory">*</span>
										</label>
										<textarea
											value={dropHintInputData.personalMessage}
											placeholder="Add A Personal Message Here ..."
											className={
												dropHintInputDataValidation.personalMessageval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "personalMessage", "input")
											}></textarea>
										{dropHintInputDataValidation.personalMessageval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.personalMessageval}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* Drop A Hint Modal Ends */}

			{/* E-Mail A Friend Modal Starts */}
			<Modal
				title="E-Mail A Friend"
				visible={emailFriendModal}
				style={{ top: 20 }}
				width={700}
				className="email_a_friend"
				maskClosable={false}
				onCancel={closeEmailFriendModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeEmailFriendModal}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleSubmitEmailFriend}
						loading={buttonLoading}>
						Send To Friend
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Name<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={emailFriendInputData.name}
											placeholder="Enter Your Name"
											className={
												emailFriendInputDataValidation.nameval && "border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "name", "input")
											}
										/>
										{emailFriendInputDataValidation.nameval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your E-Mail<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={emailFriendInputData.email}
											placeholder="Enter Your E-Mail"
											className={
												emailFriendInputDataValidation.emailval && "border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "email", "input")
											}
										/>
										{emailFriendInputDataValidation.emailval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Friend's Name <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={emailFriendInputData.friendName}
											placeholder="Enter Your Friend's Name"
											className={
												emailFriendInputDataValidation.friendNameval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "friendName", "input")
											}
										/>
										{emailFriendInputDataValidation.friendNameval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.friendNameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Friend's E-Mail <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={emailFriendInputData.friendEmail}
											placeholder="Enter Your Friend's E-Mail"
											className={
												emailFriendInputDataValidation.friendEmailval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "friendEmail", "input")
											}
										/>
										{emailFriendInputDataValidation.friendEmailval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.friendEmailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>
											Add A Personal Message Here ...{" "}
											<span className="mandatory">*</span>
										</label>
										<textarea
											value={emailFriendInputData.personalMessage}
											placeholder="Add A Personal Message Here ..."
											className={
												emailFriendInputDataValidation.personalMessageval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "personalMessage", "input")
											}></textarea>
										{emailFriendInputDataValidation.personalMessageval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.personalMessageval}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* E-Mail A Friend Modal Ends */}

			{/* Schedule Viewing Modal Starts */}
			<Modal
				title="Schedule A Viewing"
				style={{ top: 20 }}
				width={700}
				visible={scheduleViewing}
				className="schedule_viewing"
				onCancel={closeScheduleViewingModal}
				maskClosable={false}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeScheduleViewingModal}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleSubmitSheduleViewing}
						loading={buttonLoading}>
						Schedule
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Name<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={scheduleViewingInputData.name}
											placeholder="Enter Your Name"
											className={
												scheduleViewingInputDataValidation.nameval &&
												"border__red"
											}
											onChange={(e) =>
												handleScheduleViewingChange(e, "name", "input")
											}
										/>
										{scheduleViewingInputDataValidation.nameval && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Email<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={scheduleViewingInputData.email}
											placeholder="Enter Your Email"
											onChange={(e) =>
												handleScheduleViewingChange(e, "email", "input")
											}
											className={
												scheduleViewingInputDataValidation.emailVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.emailVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.emailVal}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											Your Phone Number<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={scheduleViewingInputData.phoneNumber}
											placeholder="Enter Your Phone Number"
											onChange={(e) =>
												handleScheduleViewingChange(e, "phoneNumber", "input")
											}
											className={
												scheduleViewingInputDataValidation.phoneNumberVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.phoneNumberVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.phoneNumberVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											Location<span className="mandatory">*</span>
										</label>
										<Select
											name="location"
											optionFilterProp="children"
											value={scheduleViewingInputData.location}
											onChange={(e) =>
												handleScheduleViewingChange(e, "location", "select")
											}
											className={
												scheduleViewingInputDataValidation.locationVal
													? "border__red"
													: "border__grey"
											}>
											<Option value="">Select Location</Option>
											{locationDetails &&
												locationDetails.map((item, i) => {
													return (
														<Option value={item.LocationId}>
															{item.LocationName}
														</Option>
													);
												})}
										</Select>
										{scheduleViewingInputDataValidation.locationVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.locationVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											Appointment Date<span className="mandatory">*</span>
										</label>
										<input
											type="date"
											value={scheduleViewingInputData.appointmentDate}
											onChange={(e) =>
												handleScheduleViewingChange(
													e,
													"appointmentDate",
													"date"
												)
											}
											min={disablePastDate()}
											className={
												scheduleViewingInputDataValidation.appointmentDateVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.appointmentDateVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.appointmentDateVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											Appointment Time<span className="mandatory">*</span>
										</label>
										<Select
											name="appointmentTime"
											optionFilterProp="children"
											placeholder="Select Appointment Time"
											value={scheduleViewingInputData.appointmentTime}
											onChange={(e) =>
												handleScheduleViewingChange(
													e,
													"appointmentTime",
													"select"
												)
											}
											className={
												scheduleViewingInputDataValidation.appointmentTimeVal
													? "border__red"
													: "border__grey"
											}>
											<Option value="">Appointment Time</Option>
											<Option value="8:00 AM">8 AM</Option>
											<Option value="8:30 AM">8:30 AM</Option>
											<Option value="9:00 AM">9 AM</Option>
											<Option value="9:30 AM">9:30 AM</Option>
											<Option value="10:00 AM">10 AM</Option>
											<Option value="10:30 AM">10:30 AM</Option>
											<Option value="11:00 AM">11 AM</Option>
											<Option value="11:30 AM">11:30 AM</Option>
											<Option value="12:00 AM">12 AM</Option>
											<Option value="12:00 PM">12:30 PM</Option>
											<Option value="1:00 PM">1 PM</Option>
											<Option value="1:30 PM">1:30 PM</Option>
											<Option value="2:00 PM">2 PM</Option>
											<Option value="2:30 PM">2:30 PM</Option>
											<Option value="3:00 PM">3 PM</Option>
											<Option value="3:30 PM">3:30 PM</Option>
											<Option value="4:00 PM">4 PM</Option>
											<Option value="4:30 PM">4:30 PM</Option>
											<Option value="5:00 PM">5 PM</Option>
											<Option value="5:30 PM">5:30 PM</Option>
											<Option value="6:00 PM">6 PM</Option>
											<Option value="6:30 PM">6:30 PM</Option>
											<Option value="7:00 PM">7 PM</Option>
											<Option value="7:30 PM">7:30 PM</Option>
											<Option value="8:00 PM">8 PM</Option>
											<Option value="8:30 PM">8:30 PM</Option>
											<Option value="9:00 PM">9 PM</Option>
											<Option value="9:30 PM">9:30 PM</Option>
											<Option value="10:00 PM">10 PM</Option>
											<Option value="10:30 PM">10:30 PM</Option>
										</Select>
										{scheduleViewingInputDataValidation.appointmentTimeVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.appointmentTimeVal}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>
											Add A Message Here ...<span className="mandatory">*</span>
										</label>
										<textarea
											value={scheduleViewingInputData.message}
											placeholder="Add A Message Here ..."
											onChange={(e) =>
												handleScheduleViewingChange(e, "message", "input")
											}
											className={
												scheduleViewingInputDataValidation.messageVal &&
												"border__red"
											}></textarea>
										{scheduleViewingInputDataValidation.messageVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.messageVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-12">
									<p className="font__bold d-flex justify-between mb-0">
										<span className="font__bold">
											{locationData.locationName &&
												parse(locationData.locationName)}
										</span>
									</p>
									<p className="font__bold mb-0">
										<span className="font__bold">
											{locationData && locationData.phone}
										</span>
									</p>
									<p className="font__bold">
										<a href={`mailto:${locationData && locationData.emailID}`}>
											{locationData && locationData.emailID}
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				title="Please confirm"
				visible={confirmScheduleModal}
				width={700}
				style={{ top: 20 }}
				className="cert_modal"
				onCancel={() => setConfirmScheduleModal(false)}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={() => setConfirmScheduleModal(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleSaveScheduleViewing}
						loading={buttonLoading}>
						Schedule
					</Button>,
				]}>
				<p>Are you sure you want to schedule?</p>
			</Modal>
			{/* Schedule Viewing Modal Ends */}

			{/* Thumbnail Slider Video Modal Starts */}
			<Modal
				title=""
				style={{ top: 20 }}
				visible={isModalVisible6}
				className="video__modal"
				onCancel={handleCancel6}
				footer={false}>
				<div className="video__section">
					<video
						width="100%"
						height="80%"
						src="https://www.overnightmountings.com/gemfind/library/Images_And_Videos/F1626/F1626.video.white.mp4"
						autoPlay
						loop
						controls></video>
				</div>
			</Modal>
			{/* Thumbnail Slider Video Modal Ends */}

			{/* For Internal Use Only Modal Starts */}

			<Modal
				title="For Internal Use Only"
				style={{ top: 20 }}
				visible={internalUseModal}
				className="video__modal internal__use"
				onCancel={handleCancelInternalModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleCancelInternalModal}>
						Cancel
					</Button>,
					<Button
						key="submit"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={internalUseModalLoading}
						type="primary"
						onClick={handleSubmitInternalData}>
						Submit
					</Button>,
				]}>
				{showDealerInfo == true ? (
					<div className="p-4">
						<p>
							Vendor Name:{" "}
							{dealerInfo && dealerInfo.dealerName
								? dealerInfo.dealerName
								: "NA"}
						</p>
						<p>
							{" "}
							Vendor Company :{" "}
							{dealerInfo && dealerInfo.dealerCompany
								? dealerInfo.dealerCompany
								: "NA"}
						</p>
						<p>
							{" "}
							Vendor City/State :{" "}
							{(dealerInfo && dealerInfo.cityName) ||
							(dealerInfo && dealerInfo.stateName)
								? dealerInfo.cityName + "/" + dealerInfo.stateName
								: "NA"}
						</p>
						<p>
							{" "}
							Vendor Phone Number:
							{dealerInfo && dealerInfo.dealerPhone
								? dealerInfo.dealerPhone
								: "NA"}
						</p>
						<p>
							{" "}
							Vendor E-Mail:
							{dealerInfo && dealerInfo.dealerEmail
								? dealerInfo.dealerEmail
								: "NA"}
						</p>
						<p>
							{" "}
							<label className="font__bold">
								{" "}
								Vendor lot number of the item:
							</label>{" "}
							{dealerInfo1 && dealerInfo1.dealerInventoryNo
								? dealerInfo1.dealerInventoryNo
								: "NA"}
							{}
						</p>
						<p>
							{" "}
							<label className="font__bold"> WholeSale Price:</label>{" "}
							{dealerInfo1 && dealerInfo1.costperCarat
								? dealerInfo1.costperCarat
								: "NA"}
						</p>
						<p>
							{" "}
							<label className="font__bold"> Diamond ID:</label>{" "}
							{dealerInfo1 && dealerInfo1.dInventoryID
								? dealerInfo1.dInventoryID
								: "NA"}
						</p>
						<p>
							<label className="font__bold"> Address: </label>{" "}
							{dealerInfo && dealerInfo.dealerAddress
								? dealerInfo.dealerAddress
								: "NA"}
						</p>
					</div>
				) : (
					<div className="form__fields border-0">
						<div className="col-md-6">
							<div className="input__block">
								<label htmlFor="">
									GF Password <span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="password"
									name="password"
									value={internalUseData.password}
									onChange={(e) => handlepasswordchange(e, "password", "input")}
								/>
								<div>
									{internalUseDataValidation.passwordVal && (
										<p className="error-color-red">
											{internalUseDataValidation.passwordVal}
										</p>
									)}
								</div>
							</div>
							<span
								onClick={() => {
									setInternalUseData({
										password: "",
									});
									window.open(`${document.referrer}?resetField=true`);
								}}
								className="linkText">
								Reset Password
							</span>
						</div>
					</div>
				)}
			</Modal>
			<Modal
				title="Fullscreen"
				visible={isModalVisibleFullscreen}
				className="fullscreen__modal"
				onCancel={handleCancelFullscreen}
				footer={false}
				width={800}
				style={{ top: 20 }}>
				<div className="thimage__slider">
					<ImageGallery
						items={images}
						showFullscreenButton={false}
						renderLeftNav={renderLeftNav}
						renderRightNav={renderRightNav}
					/>
				</div>
			</Modal>
			{/* For Internal Use Only Modal Ends */}

			{/* Modal Section Ends */}
		</React.Fragment>
	);
};

export default ChooseSettingItemDetails;
